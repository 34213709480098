import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Optional,
  ViewChild,
} from '@angular/core';
import { AngularResizeElementDirection } from 'src/app/core/enums/angular-resize-element.enum';
// @ts-ignore
import WebViewer from '@pdftron/pdfjs-express';
import { FormatFile } from 'src/app/features/library/interfaces/format-files.interfaces';

@Component({
  selector: 'app-pdf-viewer-modal',
  templateUrl: './pdf-viewer-modal.component.html',
  host: {
    class: 'relative block w-svw max-w-3xl h-svh',
    '[class.max-w-7xl]': 'isExpanded'
  }
})
export class PdfViewerModalComponent implements AfterViewInit {
  viewerInstance: any = null;
  isExpanded: boolean = false;
  public readonly AngularResizeElementDirection = AngularResizeElementDirection;

  @ViewChild('viewer', { static: false }) viewer!: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogRef: DialogRef,
    @Optional()
    @Inject(DIALOG_DATA)
    private dialogData: string | FormatFile
  ) {}

  openDoc(url: string): void {
    this.viewerInstance.UI.loadDocument(url);
  }

  initializeWebViewer(): void {
    WebViewer(
      {
        licenseKey: 'lymr76e0MbRmMfKFiaUH',
        path: '../viewer',
      },
      this.viewer.nativeElement
    ).then((instance: any) => {
      this.viewerInstance = instance;
      instance.disableTools(['TextHighlightCreate']);
      instance.setLanguage('es');
      instance.disableElements(['contextMenuPopup']);

      instance.setHeaderItems((header: any) => {
        /* Boton personalizado de expandir visor */
        header.push({
          type: 'statefulButton',
          initialState: 'expand',
          states: {
            expand: {
              title: 'Expandir',
              img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style="--ng-icon__size: 1.375rem; width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em)" fill="currentColor"><path fill-rule="evenodd" d="M4.177 7.823l2.396-2.396A.25.25 0 017 5.604v4.792a.25.25 0 01-.427.177L4.177 8.177a.25.25 0 010-.354z"></path><path fill-rule="evenodd" d="M0 1.75C0 .784.784 0 1.75 0h12.5C15.216 0 16 .784 16 1.75v12.5A1.75 1.75 0 0114.25 16H1.75A1.75 1.75 0 010 14.25V1.75zm1.75-.25a.25.25 0 00-.25.25v12.5c0 .138.112.25.25.25H9.5v-13H1.75zm12.5 13H11v-13h3.25a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25z"></path></svg>',
              onClick: (update: any, activeState: any) => {
                update('shrink');
                this.expandViewer();
                this.cdRef.detectChanges();
              },
            },
            shrink: {
              title: 'Contraer',
              img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style="--ng-icon__size: 1.375rem; width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em)" fill="currentColor"><path fill-rule="evenodd" d="M6.823 7.823L4.427 5.427A.25.25 0 004 5.604v4.792c0 .223.27.335.427.177l2.396-2.396a.25.25 0 000-.354z"></path><path fill-rule="evenodd" d="M1.75 0A1.75 1.75 0 000 1.75v12.5C0 15.216.784 16 1.75 16h12.5A1.75 1.75 0 0016 14.25V1.75A1.75 1.75 0 0014.25 0H1.75zM1.5 1.75a.25.25 0 01.25-.25H9.5v13H1.75a.25.25 0 01-.25-.25V1.75zM11 14.5v-13h3.25a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25H11z"></path></svg>',
              onClick: (update: any, activeState: any) => {
                update('expand');
                this.expandViewer();
                this.cdRef.detectChanges();
              },
            },
          },
        });

        // Botón para descargar si el pdf viene de formatos y tiene un documento editable
        if (
          this.dialogData &&
          typeof this.dialogData === 'object' &&
          this.dialogData['idFile']
        ) {
          header.push({
            type: 'actionButton',
            title: 'Descargar editable',
            img: '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>',
            onClick: () => {
              if (this.dialogData && typeof this.dialogData === 'object') {
                this.closeViewer({ download: true });
              }
              this.cdRef.detectChanges();
            },
          });
        }

        /* Botón personalizado de cerrar visor */
        header.push({
          type: 'actionButton',
          title: 'Cerrar',
          img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="--ng-icon__size: 1.375rem; width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em)" fill="currentColor"><path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"></path></svg>',
          onClick: () => {
            this.closeViewer();
            this.cdRef.detectChanges();
          },
        });
      });

      if (this.dialogData && typeof this.dialogData === 'string') {
        this.openDoc(this.dialogData.toString());
      } else if (this.dialogData && typeof this.dialogData === 'object') {
        this.openDoc(this.dialogData['path']);
      }
    });
  }

  closeViewer(data?: unknown): void {
    this.isExpanded = false;
    this.dialogRef.close(data);
  }

  expandViewer(): void {
    this.isExpanded = !this.isExpanded;
  }

  ngAfterViewInit(): void {
    this.initializeWebViewer();
  }
}
