<div class="py-2 px-5 space-y-2">
  <div>
    <ngx-dropzone (change)="onSelect($event)" [id]="'fileDropzoneControl'">
      <ngx-dropzone-label class="p-6">{{'FILE_DROPZONE_PROMPT' | translate}}</ngx-dropzone-label>
    </ngx-dropzone>
  </div>

  <div *ngIf="files[0]" class="space-y-1">
    <h2 class="flex items-baseline gap-2 pb-1 font-medium border-b border-slate-200/60">
      <i class="fa-light fa-files"></i>
      Documentos agregados:
    </h2>

    <div class="max-w-full min-w-0">
      <div *ngFor="let f of files" class="flex justify-between max-w-full gap-4 min-w-0">
        <div class="flex gap-2 items-center max-w-[calc(100%-6rem)]">
          <i class="text-indigo-500 fa-solid" [ngClass]="f.name.split('.').pop()! | extToIcon"
            style="font-size: 1rem;"></i>
          <span class="text-sm truncate">{{ f.name }}</span>
        </div>
        <div class="flex items-baseline gap-2">
          <span class="text-sm text-slate-600 whitespace-nowrap">{{ f.size | fileSize }}</span>
          <button (click)="onRemove(f)" class="border-0 btn group shadow-none" [ngxTippy]="'DELETE_STRING' | translate">
            <i class="fa-regular fa-delete-left text-rose-500 group-hover:text-rose-600" style="font-size: 1rem;"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="saving | async" class="my-2 flex items-center justify-center gap-1 text-slate-600">
    <i class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i> Cargando documento
  </div>


</div>