<button *ngFor="let searchObject of objects" type="button"
  class="enabled:hover:bg-indigo-50 flex items-center gap-2 p-2 group border w-full border-transparent"
  (click)="onSelectClick(searchObject)" [disabled]="docPickerService.isExisting(searchObject)"
  [ngClass]="{'bg-indigo-50 !border-indigo-600 !relative': isSelected(searchObject)}">
  <i class="fa-regular text-[1rem] text-slate-600" [ngClass]="'pdf' | extToIcon"></i>
  <span class="font-medium text-slate-600 truncate text-sm">{{ searchObject.heading | titlecase }}</span>
  <div class="px-3 ml-auto pointer-events-none flex items-center">
    <div class="size-6 rounded-full grid place-content-center text-indigo-600 bg-indigo-100"
      [ngClass]="isSelected(searchObject) ? 'visible': 'invisible'">
      <i class="fa-solid fa-check text-[0.9rem]"></i>
    </div>
  </div>
</button>