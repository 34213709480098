import { Dialog, DialogConfig } from '@angular/cdk/dialog';
import {
    Overlay,
    STANDARD_DROPDOWN_BELOW_POSITIONS,
} from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { ElementRef, inject, Injectable, TemplateRef } from '@angular/core';
import {
    DEFAULT_DIALOG_OPTIONS,
    DEFAULT_DROPDOWN_OPTIONS,
    DEFAULT_PREVIEW_OPTIONS,
    DEFAULT_SIDE_PANEL_OPTIONS,
} from '../constants/dialog.constants';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private readonly dialog = inject(Dialog);
    private readonly overlay = inject(Overlay);

    private defaultDialogOptions = DEFAULT_DIALOG_OPTIONS;
    private defaultSidePanelOptions = DEFAULT_SIDE_PANEL_OPTIONS;
    private defaultPreviewOptions = DEFAULT_PREVIEW_OPTIONS;
    private defaultDropdownOptions = DEFAULT_DROPDOWN_OPTIONS;

    /** Abre un modal en el centro de la ventana, emite un valor cuando el dialogo se cierra */
    openDialog<T>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        componentOptions?: DialogConfig
    ) {
        const options: any = this.mergeOptions(
            this.defaultDialogOptions,
            componentOptions
        );
        const dialog = this.dialog.open(componentOrTemplateRef, options);

        return dialog.closed;
    }

    /** Abre un dialogo con estilos de sidePanel a la izquierda de la ventana, emite un valor cuando el dialogo se cierra */
    openSidePanel<T>(
        component: ComponentType<T>,
        componentOptions?: DialogConfig
    ) {
        const options: any = this.mergeOptions(
            this.defaultSidePanelOptions,
            componentOptions
        );
        const dialog = this.dialog.open(component, {
            ...options,
            positionStrategy: this.overlay
                .position()
                .global()
                .right('0')
                .bottom('0'),
        });

        return dialog.closed;
    }

    /** Abre la ventana para pre-visualizar archivos sin estilos extra */
    openPreview<T>(
        component: ComponentType<T>,
        componentOptions?: DialogConfig
    ) {
        const options: any = this.mergeOptions(
            this.defaultPreviewOptions,
            componentOptions
        );
        const dialog = this.dialog.open(component, options);

        return dialog.closed;
    }

    /** Abre un dropdown conectado a un elemento que recibe como targetRef, emite un valor al cerrar el modal */
    openDropdown<T>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        targetRef: ElementRef,
        componentOptions?: DialogConfig
    ) {
        const options: any = this.mergeOptions(
            this.defaultDropdownOptions,
            componentOptions
        );
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(targetRef)
            .withPositions(STANDARD_DROPDOWN_BELOW_POSITIONS)
            .withViewportMargin(8);

        const dialog = this.dialog.open(componentOrTemplateRef, {
            positionStrategy,
            ...options,
        });

        return dialog.closed;
    }

    /** Combina las opciones por defecto y permite sobre-escribirlas o agregar opciones de dialogo extras */
    private mergeOptions(
        defaultOptions: DialogConfig,
        newInformation?: DialogConfig
    ) {
        return { ...defaultOptions, ...newInformation };
    }

    closeAll() {
        this.dialog.closeAll();
    }
}
