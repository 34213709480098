import Step from 'shepherd.js/src/types/step';

export const REPORTS_STEPS: Step.StepOptions[] = [
    {
        title: 'Bienvenido a Reportes',
        text: `<p>Aquí encontrarás una vista general de tus reportes y carpetas. Podrás organizar, buscar y gestionar toda la información necesaria para tus análisis.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('folder-section') || '#folder-section',
            on: 'left',
        },
        showOn() {
            return (document.getElementById('folder-section') ||
                false) as boolean;
        },
        title: 'Carpetas de Reportes',
        text: `<p>Aquí puedes ver y gestionar las carpetas donde organizas tus reportes. Selecciona una carpeta para acceder a su contenido.</p>`,
    },
    {
        attachTo: {
            element: document.getElementById('reports-list') || '#reports-list',
            on: 'bottom',
        },
        showOn() {
            return (document.getElementById('reports-list') ||
                false) as boolean;
        },
        title: 'Lista de Reportes',
        text: `<p>En esta sección verás todos tus reportes disponibles, organizados por nombre, fecha de creación y el usuario que los creó.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('add-folder-button') ||
                '#add-folder-button',
            on: 'top',
        },
        showOn() {
            return (document.getElementById('add-folder-button') ||
                false) as boolean;
        },
        title: 'Crea una Carpeta',
        text: `<p>Haz clic aquí para crear una nueva carpeta donde podrás organizar tus reportes de manera eficiente.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('reports-filter') || '#reports-filter',
            on: 'bottom',
        },
        showOn() {
            return (document.getElementById('reports-filter') ||
                false) as boolean;
        },
        title: 'Filtra tus Reportes',
        text: `<p>Usa esta herramienta para buscar reportes específicos. Filtra por nombre, fecha o creador para encontrar rápidamente lo que necesitas.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('create-report-button') ||
                '#create-report-button',
            on: 'top',
        },
        showOn() {
            return (document.getElementById('create-report-button') ||
                false) as boolean;
        },
        beforeShowPromise: function () {
            return new Promise<void>((resolve) => {
                const closeModalButton = document.querySelector(
                    '#close-add-report'
                ) as HTMLElement;
                if (closeModalButton) {
                    closeModalButton?.click();
                }
                resolve();
            });
        },
        title: 'Crea un Reporte',
        text: `<p>Selecciona esta opción para crear un nuevo reporte. Se abrirá un formulario donde podrás configurar todos los detalles del reporte.</p>`,
    },
];
