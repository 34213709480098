import Swal, { SweetAlertOptions } from 'sweetalert2';

export const ALERT_DEFAULTS: SweetAlertOptions = {
	icon: 'error',
	title: '¡Error!',
	confirmButtonText: 'Aceptar',
	cancelButtonText: 'Cancelar',
	allowOutsideClick: true,
	customClass: {
		popup: 'bg-slate-100 text-slate-700',
		confirmButton: 'uisr-primary-button',
		cancelButton: 'ml-2 uisr-secondary-button',
		input: 'text-center'
	},
	buttonsStyling: false
};

export const ALERT_TOAST_DEFAULTS: SweetAlertOptions = {
	position: 'top-end',
	toast: true,
	timer: 5000,
	timerProgressBar: true,
	showConfirmButton: false,
	showCloseButton: true,
	didOpen: (toast) => {
		toast.addEventListener('click', ()=> Swal.close())
	}
};
