import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import Swal from 'sweetalert2';
import { DialogService } from '../../../core/services/dialog.service';
import { IOptions } from '../../interfaces/autocomplete-options.interface';
import { ManageUserGroupComponent } from '../manage-user-group/manage-user-group.component';

@Component({
  selector: 'app-manage-dossier-access',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    NgxTippyModule,
  ],
  templateUrl: './manage-dossier-access.component.html',
  host: {
    class: 'flex flex-col gap-2',
  },
})
export class ManageDossierAccessComponent {
  selectedMembers: any[] = [];
  selectedGroups: any[] = [];
  selectedType = 'all';
  dialogData = inject(DIALOG_DATA, { optional: true });
  dialogRef = inject(DialogRef, { optional: true });

  private readonly dialogService = inject(DialogService);

  @Input({ required: true }) members: any[] = [];
  @Input({ required: true }) memberId!: number;
  @Input({ required: true }) groups!: IOptions[];
  @Input() defaultMembers: any[] = [];

  @ViewChild('memberSelect') memberSelect!: NgSelectComponent;
  @ViewChild('groupSelect') groupSelect?: NgSelectComponent;

  constructor() {
    if (this.dialogData && this.dialogRef) {
      this.members = this.dialogData.members || [];
      this.defaultMembers = this.dialogData.defaultMembers || [];
      this.groups = this.dialogData.groups || [];
      this.memberId = this.dialogData.memberId;
      this.setInitialData();
    }
  }

  changeType(type: string) {
    this.selectedType = type;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes['members'] &&
      changes['members'].currentValue != undefined
    ) {
      this.setInitialData();
    }
  }

  setInitialData() {
    this.members.forEach((m) => {
      m.canEditAll =
        m.permissions &&
        m.permissions.some((p: string) => p == 'expedientes:editar');
      m.canReadAll =
        m.permissions &&
        m.permissions.some((p: string) => p == 'expedientes:leer');
    });

    if (this.groups) {
      this.groups = this.groups.map((g) => ({
        ...g,
        access: {
          read: g['config'].readDossier,
          write: g['config'].writeDossier,
        },
      }));
    }
    if (
      !this.defaultMembers[0] &&
      !this.selectedMembers.some((m) => m.idMembersWorkspace == this.memberId)
    ) {
      // Buscar el creador en el arreglo de miembros para marcarlo con acceso de lectura y edición siempre
      const creator = this.members.find(
        (m) => m.idMembersWorkspace == this.memberId
      );
      if (creator) {
        this.selectedMembers.push({
          ...creator,
          access: { read: true, write: true },
          isCreator: true,
          isDisabled: true,
        });
      }
    }

    if (this.defaultMembers[0]) {
      const accessMap = new Map<number, any>();
      const groupAccessMap = new Map<number, any>();

      this.defaultMembers.forEach((def) => {
        if (def.fkIdMemberWorkspace) {
          accessMap.set(+def.fkIdMemberWorkspace, def.access);
        } else {
          groupAccessMap.set(+def.fkIdGroup, def.access);
        }
      });

      // Asignar los miembros con los permisos guardados previamente
      this.selectedMembers = this.members
        .filter((m) => accessMap.has(+m.idMembersWorkspace))
        .map((m) => {
          const access = { ...accessMap.get(+m.idMembersWorkspace) };

          // Verificar si el miembro tiene el permiso de editar todos los expedientes
          if (m.canEditAll) {
            access.write = true;
          }

          return {
            ...m,
            access,
            isCreator: m.idMembersWorkspace == this.memberId,
          };
        });

      // Asignar los grupos con los permisos guardados previamente
      const selectedGroups = this.groups
        .filter((g) => groupAccessMap.has(+g['idUserGroup']))
        .map((g) => ({
          ...g,
          // Asignar los permisos guardados previamente
          access: groupAccessMap.get(+g['idUserGroup']),
        }));

      if (selectedGroups) {
        this.onGroupChange(selectedGroups);
      }
    }
  }

  onReadChange(member: any, value: boolean) {
    // Si se elimina el permiso de leer, se remueve el permiso de escritura y se elimina del arreglo de miembros seleccionados
    if (!value) {
      member.access = { read: false, write: false };
      this.selectedMembers = this.selectedMembers.filter(
        (m) => m.idMembersWorkspace != member.idMembersWorkspace
      );
    }
  }

  /** Se ejecuta cuando el permiso de leer de un grupo cambia y hay que actualizar los grupos y miembros seleccionados */
  onGroupReadChange(group: any, value: boolean) {
    // Solo actualizar si se remueve el permiso de lectura
    if (!value) {
      // Remover el grupo del arreglo de grupos con acceso (selectedGroups)
      group.access = { read: true, write: false };
      this.selectedGroups = this.selectedGroups.filter(
        (g) => g.idUserGroup !== group.idUserGroup
      );

      // Iterar sobre los miembros del grupo eliminado para determinar si hay que eliminarlo de selectedMembers o actualizar su nivel de acceso
      group.members.forEach((member: any) => {
        const originalMember = this.members.find(
          (m) => m.idMembersWorkspace == member.memberId
        );
        const isCreator = member.memberId == this.memberId;
        const remainingGroups = this.selectedGroups.filter(
          (selectedGroup: any) =>
            selectedGroup.members.some(
              (existingMember: any) =>
                existingMember.memberId == member.memberId
            )
        );

        // Si el miembro no aparece en ningún otro grupo, hay que eliminarlo de selectedMembers
        if (!remainingGroups.length && !isCreator) {
          this.selectedMembers = this.selectedMembers.filter(
            (m) => m.idMembersWorkspace != member.memberId
          );
        }

        const selectedMember = this.selectedMembers.find(
          (selectedMember) =>
            selectedMember.idMembersWorkspace == member.memberId
        );

        // Actualizar el nivel de acceso del miembro que aparece en otro grupo
        if (selectedMember) {
          selectedMember.access = {
            write:
              originalMember.canEditAll ||
              remainingGroups.some((group: any) => group.access.write),
            read: true,
          };
        }
      });
    }
  }

  onGroupWriteChange(group: any) {
    // Iterar sobre los miembros del grupo para actualizar su nivel de acceso de escritura
    group.members.forEach((member: any) => {
      const originalMember = this.members.find(
        (m) => m.idMembersWorkspace == member.memberId
      );
      const isCreator = member.memberId == this.memberId;
      const groups = this.selectedGroups.filter((selectedGroup: any) =>
        selectedGroup.members.some(
          (existingMember: any) => existingMember.memberId == member.memberId
        )
      );
      const existingMember = this.selectedMembers.find(
        (sm) => sm.idMembersWorkspace == member.memberId
      );

      // Actualizar el acceso de escritura del miembro sobre el que se está iterando
      if (groups.length && existingMember && !isCreator) {
        existingMember.access = {
          write:
            originalMember.canEditAll ||
            groups.some((group: any) => group.access.write),
          read: true,
          fromGroup: true,
        };
      }
    });
  }

  onGroupChange(groups: any) {
    const groupIds = new Set<number>();
    const memberIds = new Set<number>();
    const memberAccess: { [key: number]: { write: boolean; read: boolean } } =
      {};

    groups.forEach((group: any) => {
      groupIds.add(group.idUserGroup);
      if (group.hasOwnProperty('members') && Array.isArray(group.members)) {
        const writeDossier =
          (group.access && group.access.write) ||
          (group.config && group.config.writeDossier);

        group.members.forEach((member: any) => {
          const canWrite = this.selectedMembers.some(
            (sm) =>
              sm.idMembersWorkspace == member.memberId &&
              sm.access.write &&
              sm.access.fromGroup
          );
          if (member && member.hasOwnProperty('memberId')) {
            const memberId = Number(member.memberId);
            memberIds.add(memberId);

            if (!memberAccess[memberId]) {
              // Acceso por defecto siempre es lectura
              memberAccess[memberId] = { write: canWrite, read: true };
            }

            if (writeDossier) {
              // Si cualquiera de los grupos tiene acceso de escritura, entonces el miembro tiene acceso de escritura
              memberAccess[memberId] = { write: true, read: true };
            }
          }
        });
      }

      const selectedGroup = structuredClone(
        this.groups.find((group) => groupIds.has(group['idUserGroup']))
      );

      if (selectedGroup) {
        this.selectedGroups.push({
          ...selectedGroup,
          access: group.access,
        });
      }
    });

    const uniqueMemberIds = Array.from(memberIds);

    uniqueMemberIds.forEach((id) => {
      const member = this.members.find(
        (m) => Number(m.idMembersWorkspace) === id
      );
      if (member) {
        const existingMember = this.selectedMembers.find(
          (sm) => sm.idMembersWorkspace == id
        );
        if (
          existingMember &&
          existingMember.idMembersWorkspace != this.memberId
        ) {
          existingMember.fromGroup = true;
          existingMember.access = memberAccess[id];
        } else if (member.idMembersWorkspace != this.memberId) {
          this.selectedMembers.push({
            ...member,
            access: memberAccess[id],
            fromGroup: true,
          });
        }
      }
    });

    if (this.groupSelect) {
      this.groupSelect.writeValue(null);
    }
  }

  onMemberChange(members: any) {
    if (members && members[0]) {
      const member = {
        ...members[0],
        access: { read: true, write: true },
      };
      this.selectedMembers.push(member);
      this.memberSelect.writeValue(null);
    }
  }

  createGroup() {
    this.dialogService
      .openDialog(ManageUserGroupComponent, {
        data: {
          members: this.members,
        },
      })
      .subscribe((data: any) => {
        if (data) {
          this.groups = [...this.groups, { ...data, label: data.name }];
        }
      });
  }

  isSelected(id: number) {
    return this.selectedMembers.some((m) => m.idMembersWorkspace == id);
  }

  isGroupSelected(id: number) {
    return this.selectedGroups.some((g) => g.idUserGroup == id);
  }

  getMemberGroups(member: any): string[] {
    return this.selectedGroups
      .filter((group) =>
        group.members?.some(
          (existingMember: any) =>
            existingMember.memberId == member.idMembersWorkspace
        )
      )
      .map((group) => group.name);
  }

  save() {
    Swal.fire({
      ...ALERT_DEFAULTS,
      html: `<span class='font-semibold'>¿Deseas guardar los cambios realizados al equipo encargado?</span>`,
      icon: 'question',
      title: undefined,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        this.dialogRef?.close([
          ...this.selectedMembers.filter((el) => !el.fromGroup),
          ...this.selectedGroups,
        ]);
      }
    });
  }
}
