<div class="comentario-container rounded-lg shadow-sm" id="component-activity">
    <!-- Contenedor editable que simula un textarea -->

    <div class="flex w-full">
        <!-- class="form-input editable text-slate-500"  -->
        <div id="comentarioTextoInput" contenteditable="true" #comentarioTextoInput  class="form-input min-h-[6.25rem] max-h-[6.25rem] w-full overflow-auto break-all"
            placeholder="Escribe un comentario..." (input)="procesarCambios($event);" (keyup)="checkForTag($event)">
        </div>  
    </div> 
    <div [ngClass]="{'invisible -z-60':!mostrarUsuarios || !posicionUsuariosListAjustada}"
        class="absolute right-0 w-max z-10 bg-white border border-gray-300 rounded-md p-2 list-none max-h-48 overflow-y-auto"
        #usuariosList id="usuariosList">
        <ul *ngIf="mostrarUsuarios">
            <li class="px-2 text-sm text-gray-500 border-b border-gray-300">
                Continúa escribiendo para ver más…
            </li>
            <li *ngFor="let usuario of usuariosFiltrados; let i = index" [attr.id]="'usuario-' + i"
                [ngClass]="{'bg-indigo-100 text-indigo-700' : i == usuarioSeleccionadoIndex}"
                class="p-2 hover:bg-gray-100 cursor-pointer" (click)="seleccionarUsuario(usuario)">
                <div class="flex items-center">
                    <img [src]="usuario.selfie ? usuario.selfie : '/assets/images/profile-placeholder.png'"
                        alt="Foto de usuario" class="w-8 h-8 rounded-full mr-2" (error)="onImageError($event)">
                    <span>{{ usuario.label }}</span>
                </div>
            </li>
            <li *ngIf="!usuariosFiltrados[0]">
                <span class="text-gray-500">No se encontraron resultados</span>
            </li>
        </ul>
    </div>
</div>