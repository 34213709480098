import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { ComponentBlocker } from 'src/app/core/utils/component-blocker';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-comentario-input',
  templateUrl: './comentario-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComentarioInputComponent),
      multi: true
    }
  ]
})
export class ComentarioInputComponent implements ControlValueAccessor {

  mostrarUsuarios = false;
  @Input('usuarios') usuarios: any[] = [];
  usuariosFiltrados: any[] = [];
  @ViewChild('comentarioTextoInput', { static: false }) comentarioTextoInput!: ElementRef;
  @ViewChild('usuariosList', { static: false }) usuariosList!: ElementRef;
  usuarioSeleccionadoIndex: number = 0;
  public posicionUsuariosListAjustada = false;
  public loadingSendMessage = new BehaviorSubject(false);
  isSendingMessage: boolean = false;
  resources = RESOURCES;

  // Guarda la última posición del cursor
  lastRange: Range | null = null;

  @Output() sendTextEvent = new EventEmitter<string>();

  @HostListener('keyup', ['$event'])
  keyup(event: KeyboardEvent) {
    const sel: any = window.getSelection();
    if (sel.rangeCount > 0) {
      this.lastRange = sel.getRangeAt(0);
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent) {
    const clickedInside = this.elRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.mostrarUsuarios = false;
      this.posicionUsuariosListAjustada = false;
      // El clic fue fuera del componente, puedes realizar aquí la acción que necesites
    }
  }

  @HostListener('document:keydown', ['$event'])
  manejarTecladoGlobal(event: KeyboardEvent) {
    if (!this.mostrarUsuarios) {
      return;
    }
    if (this.usuariosFiltrados.length > 0) {
      if (event.key === 'ArrowDown') {
        // Seleccionar el siguiente usuario
        this.usuarioSeleccionadoIndex = Math.min(this.usuarioSeleccionadoIndex + 1, this.usuariosFiltrados.length - 1);
        event.preventDefault();
        return
      } else if (event.key === 'ArrowUp') {
        // Seleccionar el usuario anterior
        this.usuarioSeleccionadoIndex = Math.max(this.usuarioSeleccionadoIndex - 1, 0);
        event.preventDefault();
        return
      } else if (event.key === 'Enter') {
        // Seleccionar el usuario actual
        this.seleccionarUsuario(this.usuariosFiltrados[this.usuarioSeleccionadoIndex]);
        event.preventDefault();
        return
      }
      this.usuarioSeleccionadoIndex = 0;
    } else {
      if (event.key === 'Enter') {
        this.mostrarUsuarios = false;
        this.posicionUsuariosListAjustada = false;
        this.usuariosFiltrados = []
        event.preventDefault();
        return
      }
    }
  }

  constructor(
    private elRef: ElementRef,
    private blocker: ComponentBlocker
  ) {
    this.subscribeToLoader()
  }

  subscribeToLoader() {
    this.blocker.loaderAll.subscribe((data) => {
      if (this.isSendingMessage) {
        if (
          data.global.count < 1 ||
          !data.global.paths.includes(this.resources.postActivityComment)
        ) {
          this.loadingSendMessage.next(false);
          this.isSendingMessage = false;
          // limpiar texto
          this.comentarioTextoInput.nativeElement.innerHTML = '';
          return;
        } else {
          this.loadingSendMessage.next(true);
        }
      }

    });
  }

  checkForTag(event: KeyboardEvent): void {
    setTimeout(() => {
      const div = event.target as HTMLDivElement;
      const textoCompleto = this.obtenerTextoLimpioDeDiv(div);
      const textoHastaCursor = this.obtenerTextoHastaCursor(div);
      const ultimaPosicionDeArroba = textoHastaCursor.lastIndexOf('@');

      if (ultimaPosicionDeArroba !== -1 && !this.isWithinSpan()) {
        const textoDesdeUltimoAtHastaCursor = textoHastaCursor.slice(ultimaPosicionDeArroba + 1);
        const hayEspacioAntesDeArroba = this.verificarEspacioAntesDeArroba(textoCompleto, ultimaPosicionDeArroba);
        const arrobaEsInicioDeEtiqueta = this.verificarInicioDeEtiqueta(textoCompleto, ultimaPosicionDeArroba);
        const arrobaSinProcesarJuntoTexto = textoCompleto.slice(ultimaPosicionDeArroba);

        if (this.mostrarUsuarios && this.debeMostrarUsuarios(
          textoDesdeUltimoAtHastaCursor,
          //  hayEspacioDespuesDeArroba,
          hayEspacioAntesDeArroba,
          arrobaEsInicioDeEtiqueta
        )) {
          this.mostrarSugerenciasDeUsuarios(textoDesdeUltimoAtHastaCursor);
        } else {
          if (this.debeMostrarUsuarios(textoDesdeUltimoAtHastaCursor,
            // hayEspacioDespuesDeArroba,
            hayEspacioAntesDeArroba,
            arrobaEsInicioDeEtiqueta
          )) {
            this.mostrarSugerenciasDeUsuarios(textoDesdeUltimoAtHastaCursor);
          } else {
            this.ocultarSugerenciasDeUsuarios();
          }
        }
      } else {
        this.ocultarSugerenciasDeUsuarios();
      }
    }, 0);
  }

  obtenerTextoLimpioDeDiv(div: HTMLDivElement): string {
    return (div.innerText || div.textContent || '').replace(/\u00a0/g, ' ');
  }

  obtenerTextoHastaCursor(div: HTMLDivElement): string {
    const sel: any = window.getSelection();
    if (sel.rangeCount > 0 && div.isContentEditable) {
      const range = sel.getRangeAt(0).cloneRange();
      range.setStart(div, 0);
      return range.toString().replace(/\u00a0/g, ' ');
    }
    return '';
  }

  // verificarEspacioDespuesDeArroba(texto: string, posicionDeArroba: number): boolean { 
  //   return texto[posicionDeArroba + 1] === ' ' || texto[posicionDeArroba + 1] === undefined; 
  // }

  verificarEspacioAntesDeArroba(texto: string, posicionDeArroba: number): boolean {
    return texto[posicionDeArroba - 1] === ' ' || texto[posicionDeArroba - 1] === undefined;

  }

  verificarInicioDeEtiqueta(texto: string, posicionDeArroba: number): boolean {
    return posicionDeArroba === 0 || texto[posicionDeArroba - 1] === ' ';
  }

  debeMostrarUsuarios(
    textoDesdeUltimoAtHastaCursor: string,
    // , hayEspacioDespuesDeArroba: boolean,
    hayEspacioAntesDeArroba: boolean,
    arrobaEsInicioDeEtiqueta: boolean
  ): boolean {

    return !textoDesdeUltimoAtHastaCursor.includes(' ') /*&& hayEspacioDespuesDeArroba*/ && hayEspacioAntesDeArroba && arrobaEsInicioDeEtiqueta;
  }

  mostrarSugerenciasDeUsuarios(texto: string): void {
    // Implementa la lógica para mostrar usuarios basada en el texto
    // Esta es una función de ejemplo, necesitarás ajustarla a tu lógica específica 
    this.mostrarUsuarios = true;
    this.usuariosFiltrados = this.usuarios.filter(usuario => usuario.label.toLowerCase()
      .toLowerCase()
      .includes(texto.toLowerCase())).slice(0, 3);

    if (this.usuariosFiltrados.length > 0) {

      if (!this.posicionUsuariosListAjustada) {
        setTimeout(() => {
          this.posicionarUsuariosList();
        }, 100);
      }
    } else {
      this.ocultarSugerenciasDeUsuarios();
    }
  }

  ocultarSugerenciasDeUsuarios(): void {
    this.mostrarUsuarios = false;
    this.posicionUsuariosListAjustada = false;
  }

  isWithinSpan(): boolean {
    const sel: any = window.getSelection();
    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const spanElements = document.getElementsByTagName('span');
      for (let i = 0; i < spanElements.length; i++) {
        if (range.intersectsNode(spanElements[i])) {
          return true;
        }
      }
    }
    return false;
  }

  seleccionarUsuario(usuario: any): void {
    const comentarioTextoElement: HTMLElement | null = document.getElementById('comentarioTextoInput');
    const sel: Selection | null = window.getSelection();

    if (!comentarioTextoElement || !sel || sel.rangeCount === 0 || !this.lastRange) {
      return;
    }

    const range = this.lastRange.cloneRange();

    // Extiende el rango hacia atrás hasta encontrar el último "@"
    while (range.startOffset > 0 && range.toString().indexOf('@') !== 0) {
      range.setStart(range.startContainer, range.startOffset - 1);
    }

    // Verifica si el rango actual comienza con "@"
    if (range.toString().indexOf('@') === 0) {
      // Encuentra el final del nombre o texto después del "@"
      const textoDesdeCursor = range.cloneContents().textContent || '';
      const endIndex = textoDesdeCursor.length > 0 ? textoDesdeCursor.length : 0;

      // Ajusta el final del rango para incluir todo el texto después del "@"
      if (endIndex > 0) {
        range.setEnd(range.startContainer, range.startOffset + endIndex);
      }

      // Elimina el contenido actualmente seleccionado
      range.deleteContents();

      // Inserta el span para el usuario etiquetado
      const span: HTMLElement = document.createElement('span');
      span.textContent = `@${usuario.label}`;

      if (this.usuarios.find(u =>
        u.label == usuario.label)
        .isAsignee == true) {
        span.classList.add("inline-flex", "font-medium", "text-indigo-600");
      } else {
        span.classList.add("inline-flex", "font-medium", "text-gray-400");
      }
      range.insertNode(span);

      // Añade un espacio después del span para separar de texto siguiente
      const cursorPosition: Text = document.createTextNode('\u00A0');
      if (span.nextSibling) {
        comentarioTextoElement.insertBefore(cursorPosition, span.nextSibling);
      } else {
        comentarioTextoElement.appendChild(cursorPosition);
      }

      // Actualiza la selección para posicionar el cursor correctamente
      const newRange: Range = document.createRange();
      newRange.setStartAfter(cursorPosition);
      newRange.setEndAfter(cursorPosition);
      sel.removeAllRanges();
      sel.addRange(newRange);
    }

    comentarioTextoElement.focus();
    this.mostrarUsuarios = false;
    this.posicionUsuariosListAjustada = false;
    this.usuarioSeleccionadoIndex = 0;

    this.onInput({ target: comentarioTextoElement });
  }

  procesarCambios(event: any) {
    const comentarioTextoElement: HTMLElement = document.getElementById('comentarioTextoInput') as HTMLElement;
    let cambioEnLaPosicionDelCursor = 0; // Esta variable ayudará a ajustar la posición del cursor

    const spans = comentarioTextoElement.querySelectorAll('span');
    if (!spans) return;
    spans.forEach(span => {
      if (span) {
        // Suponemos que tienes una lista de usuarios para verificar
        const usuario = this.usuarios.find(u => `@${u.label}` === span.textContent?.trim());
        if (!usuario) {
          // Si el span ha sido modificado, conviértelo en texto plano

          comentarioTextoElement.removeChild(span);
          // Asume que cada span modificado afecta la posición del cursor
          cambioEnLaPosicionDelCursor += 1; // Ajusta según la lógica específica de tu aplicación
      

        }
      }
    });

    // Ajusta la posición del cursor solo si hubo cambios
    if (cambioEnLaPosicionDelCursor !== 0) {
      this.ajustarPosicionDelCursor(comentarioTextoElement, cambioEnLaPosicionDelCursor);
    }

    this.onInput(event);
  }

  ajustarPosicionDelCursor(comentarioTextoElement: HTMLElement, cambioEnLaPosicion: number) {
    const sel: any = window.getSelection();

    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const newRange = document.createRange();
      if (range.endContainer.nodeType === Node.TEXT_NODE) {
        let nuevaPosicion = Math.max(range.endOffset + cambioEnLaPosicion, 0);
        nuevaPosicion = Math.min(nuevaPosicion, range.endContainer.textContent.length); // Asegura no exceder la longitud del texto

        newRange.setStart(range.endContainer, nuevaPosicion);
        newRange.setEnd(range.endContainer, nuevaPosicion);
      } else {
        // Si el endContainer no es un nodo de texto, encuentra el nodo de texto más cercano
        let node = range.endContainer.childNodes[range.endOffset] || range.endContainer.lastChild;
        while (node && node.nodeType !== Node.TEXT_NODE) {
          node = node.lastChild;
        }

        if (node && node.nodeType === Node.TEXT_NODE) {
          let nuevaPosicion = Math.max(range.endOffset + cambioEnLaPosicion, 0);
          nuevaPosicion = Math.min(nuevaPosicion, node.textContent.length); // Asegura no exceder la longitud del texto

          newRange.setStart(node, nuevaPosicion);
          newRange.setEnd(node, nuevaPosicion);
        } else {
          // Si no se encuentra un nodo de texto, coloca el cursor al final del contenedor
          newRange.setStart(range.endContainer, range.endContainer.childNodes.length);
          newRange.setEnd(range.endContainer, range.endContainer.childNodes.length);
        }
      }

      sel.removeAllRanges();
      sel.addRange(newRange);
    }


    comentarioTextoElement.focus(); // Devuelve el foco al elemento
    setTimeout(() => {
      this.mostrarUsuarios = false; // Oculta sugerencias
      this.posicionUsuariosListAjustada = false;

    }, 100)
  }

  posicionarUsuariosList(): void {
    // Detectar que tipo de dispositivo  
    const sidebarElement: any = document.getElementById('component-activity');
    const isLg = window.innerWidth >= 1024;
    const widthInPixels: any = (sidebarElement?.offsetWidth) / 3;

    setTimeout(() => {
      const usuariosListEl = this.usuariosList.nativeElement;
      const sel: any = window.getSelection();
      const range: any = sel.getRangeAt(0);
      const rect: any = range.getBoundingClientRect();
      usuariosListEl.style.position = 'absolute';

      // Obtener el elemento padre
      const parentElement = this.elRef.nativeElement.parentElement;
      const parentRect = parentElement.getBoundingClientRect();

      // Calcular el espacio disponible a la derecha del elemento dentro del div padre
      const espacioDerecha = parentRect.right - rect.right;
      // Si el espacio a la derecha es menor que el ancho del elemento, ajustar la posición del elemento

      if (espacioDerecha < usuariosListEl.offsetWidth) {
        usuariosListEl.style.left = 'auto';
        usuariosListEl.style.right = '0px';
      } else {
        usuariosListEl.style.left = `${rect.left - parentRect.left - widthInPixels + 200}px`;
        usuariosListEl.style.right = 'auto';
        if (this.usuariosFiltrados.length == 1 || this.usuariosFiltrados.length < 1) {
          usuariosListEl.style.top = `${rect.top - parentRect.top + window.scrollY + 220}px`;
        } else if (this.usuariosFiltrados.length == 2) {
          usuariosListEl.style.top = `${rect.top - parentRect.top + window.scrollY + 230}px`;
        } else {
          usuariosListEl.style.top = `${rect.top - parentRect.top + window.scrollY + 220}px`;
        }
      }

      setTimeout(() => {
        this.posicionUsuariosListAjustada = true;
        // const firstElement = this.listaUsuarios.nativeElement.querySelector('li');
        // firstElement?.focus();
      }, 10);

    }, 10);
  }


  getCursorPosition() {
    const selection: any = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(this.comentarioTextoInput.nativeElement);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange.toString().length;
    }
    return 0;
  }

  sendText() {
    this.isSendingMessage = true;
    this.sendTextEvent.emit(this.comentarioTextoInput.nativeElement.textContent);
  }

  onImageError(event: any) {
    event.target.src = 'assets/images/profile-placeholder.png';
  }

  // ControlValueAccessor implementation
  private onChange: (value: string) => void = () => { };
  private onTouched: () => void = () => { };

  writeValue(value: string): void {
    if (this.comentarioTextoInput) {
      this.comentarioTextoInput.nativeElement.innerHTML = value || '';
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.comentarioTextoInput) {
      this.comentarioTextoInput.nativeElement.disabled = isDisabled;
    }
  }

  onInput(event: any): void {
    const value = this.replaceMentionsWithIds(this.comentarioTextoInput.nativeElement.textContent);
    this.onChange(value);
    this.onTouched();
  }

  replaceMentionsWithIds(event: any): string {
    let message = event;
    this.usuarios.forEach((item) => {
      message = message.replaceAll(`@${item.label}`, `@--/${item.id}/--@`);
    });
    return message.replace('', '\n').trim();
  }

}
