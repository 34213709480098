import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEsMx from '@angular/common/locales/es-MX';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
  SecurityContext
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './components/app/app.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { CoreRoutingModule } from './core-routing.module';
import { ValidateAccessDirective } from './directives/validate-access.directive';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SearchReducer } from './reducer/search/search.reducer';
import { SimpleUserDataEffects } from './reducer/user-data/simple-user-data.effects';
import { UserDataEffects } from './reducer/user-data/user-data.effects';
import { UserReducer } from './reducer/user-data/user-data.reducer';
import { UisrTranslateService } from './services/uisr-translate.service';
import { ComponentBlocker } from './utils/component-blocker';

registerLocaleData(localeEsMx);

@NgModule({
    declarations: [AppComponent, MainLayoutComponent, NotFoundComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        CoreRoutingModule,
        HttpClientModule,
        ValidateAccessDirective,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: UisrTranslateService,
            },
        }),
        ToastrModule.forRoot({}),
        JwtModule.forRoot({
            config: {
                tokenGetter: () => localStorage.getItem('accessToken'),
            },
        }),
        StoreModule.forRoot({
            UserData: UserReducer,
            SearchFilter: SearchReducer,
        }),
        StoreDevtoolsModule.instrument({
            logOnly: true, // Restrict extension to log-only mode
        }),
        SharedModule,
        EffectsModule.forRoot([UserDataEffects, SimpleUserDataEffects]),
        MarkdownModule.forRoot({
            sanitize: SecurityContext.NONE,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            // registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '122024441929-d53sfdc44sbuo395fj6ku6dlte9ucuiv.apps.googleusercontent.com',
                            {
                                scopes: 'profile email',
                                oneTapEnabled: false,
                            }
                        ),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        { provide: LOCALE_ID, useValue: 'es-MX' },
        ComponentBlocker,
    ],
    bootstrap: [AppComponent],
})
export class CoreModule {}
