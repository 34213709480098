import { Injectable } from '@angular/core';
import { UserData } from '../models/user-data';
import { SubscriptionComplete } from 'src/app/shared/interfaces/subscription-complete.interface';

declare var gtag: (arg0: string, arg1: string, arg2: any) => void;

@Injectable({
  providedIn: 'root',
})
export class UisrAnalyticsService {
  trackOpenResultEvent(
    source: string,
    payload: any,
    response: any,
    success: boolean,
    time: any
  ) {
    gtag('event', 'open_result_event', {
      md_i: btoa(response.userData.id_users), // ID en base64
      l_result_source: source, // Fuente a la que pertenece al resultado
      l_result_id: payload.opensearchId, // ID del resultado que se abrió
      l_result_has_pdf: response && response.searchDetail.urlPDF ? 1 : 0, // Si el resultado tiene PDF o no
      l_result_open_success: success ? 1 : 0, // Si la búsqueda fue exitosa o no
      l_result_time: time, // Tiempo de respuesta en ms
    });
  }

  trackClickOnResultEvent(
    source: string,
    id: string,
    user: any,
    isNewTab: boolean,
    parentId: any = null,
    isRelated: boolean = false
  ) {
    gtag('event', 'click_result_event', {
      md_i: btoa(user), // ID en base64
      l_result_source: source, // Fuente a la que pertenece al resultado
      l_result_id: id, // ID del resultado que se abrió
      l_result_is_new_tab: isNewTab ? 1 : 0, // Si el resultado se abrió en una nueva página o no
      l_result_parent: parentId, // Corresponde a un ID de open search, si es diferente a null, indica que el evento corresponde a una interacción desde la sección de resultados similares
      l_result_is_related: isRelated ? 1 : 0, // Indica si se interactuó con un resultado desde la sección de resultados relacionados o no
    });
  }

  trackOpenPDFEvent(source: string, id: string, user: any) {
    gtag('event', 'open_pdf_event', {
      md_i: btoa(user), // ID en base64
      l_result_source: source, // Fuente a la que pertenece al resultado
      l_result_id: id, // ID del resultado que se abrió
    });
  }

  trackOpenContentEvent(source: string, id: string, user: any) {
    gtag('event', 'open_content_event', {
      md_i: btoa(user), // ID en base64
      l_result_source: source, // Fuente a la que pertenece al resultado
      l_result_id: id, // ID del resultado que se abrió
    });
  }

  trackCloseResultEvent(source: string, user: any) {
    gtag('event', 'close_result_event', {
      md_i: btoa(user), // ID en base64
      l_result_source: source, // Fuente a la que pertenece al resultado
    });
  }

  trackCompleteSearchEvent(
    payload: any,
    response: any,
    success: boolean,
    time: number
  ) {
    gtag('event', 'legal_search_event', {
      md_i: response ? btoa(response.userData.id_users) : null, // ID en base64
      l_search_source: payload.index[0].name, // Fuente de la búsqueda
      l_search_query: payload.query, // Query de la búsqueda
      l_search_complexity: payload.query ? payload.query.split(' ').length : 0, // Complejidad de la búsqueda (número de palabras)
      l_search_page: payload.page, // Página que esta solicitando el usuario en la búsqueda
      l_search_filters: payload.index[0].filters
        ? JSON.stringify(payload.index[0].filters)
        : null, // Los filtros de la búsqueda (json)
      l_search_success: success, // Si la búsqueda fue exitosa o no
      l_search_num_filters: payload.index[0].filters
        ? Object.keys(payload.index[0].filters).length
        : 0, // El numero de filtros
      l_search_num_results:
        response && response.searchResults
          ? response.searchResults.total
          : null, // El número de resultados
      l_search_num_pages: response ? response.pages : null,
      l_search_time: time, // Tiempo de respuesta en ms
    });
  }

  trackCollectionOpened(selectedCollection: any, userData: any) {
    gtag('event', 'collections_open_collection_event', {
      md_i: btoa(userData.id_users), // ID en base64
      u_collection_type: selectedCollection.fk_id_type_collection, // Tipo de colección abierta
      u_collection_id: selectedCollection.id_collection_user, // Query de la búsqueda
    });
  }

  trackResultOpenedFromCollections(
    selectedCollection: any,
    result: any,
    userData: any,
    isNewTab: boolean,
    parentId: any = null,
    isRelated: boolean = false
  ) {
    gtag('event', 'collections_result_open_event', {
      md_i: btoa(userData.id_users), // ID en base64
      u_collection_type: selectedCollection.fk_id_type_collection, // Tipo de colección abierta
      u_collection_id: selectedCollection.id_collection_user, // Id de la colección
      u_collection_result_id: result._id, // Tipo de colección abierta
      u_collection_result_source: result._index, // Fuente a la que pertenece el resultado
      u_collection_is_new_tab: isNewTab,
      u_collection_parent: parentId, // Corresponde a un ID de open search, si es diferente a null, indica que el evento corresponde a una interacción desde la sección de resultados similares
      u_collection_is_related: isRelated ? 1 : 0, // Indica si se interactuó con un resultado desde la sección de resultados relacionados o no
    });
  }

  trackCloseResultFromCollectionsEvent(
    selectedCollection: any,
    result: any,
    userData: any
  ) {
    gtag('event', 'collections_close_result_event', {
      md_i: btoa(userData.id_users), // ID en base64
      u_collection_type: selectedCollection.fk_id_type_collection, // Tipo de colección abierta
      u_collection_id: selectedCollection.id_collection_user, // Id de la colección
      u_collection_result_id: result._id, // Tipo de colección abierta
      u_collection_result_source: result._index, // Fuente a la que pertenece el resultado
    });
  }

  trackLoginEvent(method: string, userData: any) {
    gtag('event', 'uisr_login', {
      login_method: method,
      md_i: btoa(userData.id_users),
    });
  }

  trackOpenCase(
    sourceType: string = 'cards',
    caseId: number,
    userData: UserData
  ) {
    gtag('event', 'workspace_open_case', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_opened_from: sourceType, // Desde donde se abrió el asunto si en vista de tarjeta o listado
      w_case_id: caseId, // Id del asunto
    });
  }

  trackMassCaseUploadClick(userData: UserData) {
    gtag('event', 'asuntos_mass_upload_click', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
    });
  }

  trackMassCaseUploadSave(userData: UserData, success: boolean) {
    gtag('event', 'asuntos_mass_upload_save', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_mass_create_success: success ? 1 : 0, // Indica si la carga respondió success o no
    });
  }

  trackCaseTabClick(userData: UserData, tabName: string) {
    gtag('event', 'asuntos_tab_click', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_tab_name: tabName, // Nombre de la pestaña en la que se hizo clic
    });
  }

  trackClientTabClick(userData: UserData, tabName: string) {
    gtag('event', 'clientes_tab_click', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_tab_name: tabName, // Nombre de la pestaña en la que se hizo clic
    });
  }

  trackCaseSearchFilters(userData: UserData, filters: any, success: boolean) {
    gtag('event', 'workspace_case_search', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_case_search_filters: filters ? JSON.stringify(filters) : null, // Filtros aplicados a la búsqueda de asuntos
      w_case_search_success: success ? 1 : 0, // Si la búsqueda fue exitosa o no
    });
  }

  trackOpenClientInfo(
    sourceType: 'cards' | 'table' = 'cards',
    clientId: number,
    userData: UserData,
    button: string = ''
  ) {
    gtag('event', 'clientes_open_info', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_opened_from: sourceType, // Si fue abierto desde un card o desde el listado
      w_client_id: clientId, // ID del cliente del despacho
      w_client_button_name: button,
    });
  }

  trackMenuClick(optionName: string, userData: UserData) {
    gtag('event', 'workspace_menu_click', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_menu_option_name: optionName,
    });
  }

  trackHelpCenterClick(userData: UserData) {
    gtag('event', 'help_menu_click', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }

  trackAssistantClick(userData: UserData) {
    gtag('event', 'assistant_click', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }
  userJourneyClick(userData: UserData) {
    gtag('event', 'user_journey_click', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }
  scheduleMeetingClick(userData: UserData) {
    gtag('event', 'schedule_meeting_click', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }

  trackDocumentOpened(caseId: number, userData: UserData) {
    gtag('event', 'asuntos_visualize_doc', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_case_id: caseId, // Id del asunto
    });
  }

  trackCaseUpdate(caseId: number, userData: UserData) {
    gtag('event', 'asuntos_save_changes_info', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_case_id: caseId, // Id del asunto
    });
  }

  trackClientUpdate(clientId: number, userData: UserData) {
    gtag('event', 'clientes_save_changes_info', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_client_id: clientId, // ID del cliente del despacho
    });
  }

  trackClientDocumentOpened(clientId: number, userData: UserData) {
    gtag('event', 'clientes_visualize_doc', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      w_client_id: clientId, // ID del cliente del despacho
    });
  }

  // TODO DOCUMENTAR

  trackInvoicePdfOpened(userData: UserData) {
    gtag('event', 'cobranzas_visualize_doc', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
    });
  }

  trackInvoiceCreationAttempt(userData: UserData) {
    gtag('event', 'cobranzas_create_click', {
      md_i: btoa(userData.id_users.toString()), // User ID in base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // Workspace ID
    });
  }

  trackInvoiceUpdateAttempt(userData: UserData) {
    gtag('event', 'cobranzas_update_click', {
      md_i: btoa(userData.id_users.toString()), // User ID in base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // Workspace ID
    });
  }

  trackInvoiceCreated(userData: UserData) {
    gtag('event', 'cobranzas_created', {
      md_i: btoa(userData.id_users.toString()), // User ID in base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // Workspace ID
    });
  }

  trackInvoiceUpdated(userData: UserData) {
    gtag('event', 'cobranzas_updated', {
      md_i: btoa(userData.id_users.toString()), // User ID in base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // Workspace ID
    });
  }

  trackPaymentCreated(userData: UserData) {
    gtag('event', 'cobranzas_installment_created', {
      md_i: btoa(userData.id_users.toString()), // User ID in base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // Workspace ID
    });
  }

  trackOpenInfoClient(userData: UserData) {
    gtag('event', 'cobranzas_open_client_info', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }

  trackResendInvoice(userData: UserData) {
    gtag('event', 'cobranzas_resend_invoice', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }

  trackDeleteInvoice(userData: UserData) {
    gtag('event', 'cobranzas_delete_invoice', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }

  trackViewInstallments(userData: UserData) {
    gtag('event', 'cobranzas_view_installments', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }

  trackInstallmentAction(userData: UserData, actionType: string) {
    gtag('event', 'cobranzas_installment_action', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
      ci_action_type: actionType,
    });
  }

  trackInvoiceSearchFilters(userData: UserData, filters: any) {
    gtag('event', 'cobranzas_search', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      ci_search_filters: filters ? JSON.stringify(filters) : null, // Filtros aplicados a la búsqueda de colecciones
    });
  }

  /** Registra un evento de búsqueda en la sección de audiencias (cambio de página, cambio de filtro o filtrado manual) */
  trackAudienciasSearchEvent(
    filters: any,
    userData: UserData,
    success: boolean
  ) {
    gtag('event', 'lib_audiencias_search', {
      md_i: btoa(userData.id_users.toString()),
      lib_listados_filters: filters ? JSON.stringify(filters) : null, // Filtros aplicados al realizar la búsqueda
      search_success: success ? 1 : 0, // Si la consulta tuvo éxito o no
    });
  }

  /** Registra un evento de búsqueda en la sección de plantillas (cambio de página, cambio de filtro o filtrado manual) */
  trackPlantillasSearchEvent(
    filters: any,
    userData: UserData,
    success: boolean
  ) {
    gtag('event', 'lib_plantillas_search', {
      md_i: btoa(userData.id_users.toString()),
      lib_plantillas_filters: filters ? JSON.stringify(filters) : null, // Filtros aplicados al realizar la búsqueda
      search_success: success ? 1 : 0, // Si la consulta tuvo éxito o no
    });
  }

  /** Registra un clic en la opción de ver pdf de plantilla */
  trackPlantillasVisualizeEvent(templateId: number, userData: UserData) {
    gtag('event', 'lib_plantillas_visualize', {
      md_i: btoa(userData.id_users.toString()),
      template_id: templateId, // ID De la plantilla con la que se interactuó
    });
  }

  /** Registra un clic en la opción de descargar una plantilla */
  trackPlantillasDownloadEvent(templateId: number, userData: UserData) {
    gtag('event', 'lib_plantillas_download', {
      md_i: btoa(userData.id_users.toString()),
      template_id: templateId, // ID De la plantilla con la que se interactuó
    });
  }

  /** Registra una vista a la landing page del blog */
  trackBlogOpenEvent(userId: number) {
    const md_i = userId ? btoa(userId.toString()) : null;
    gtag('event', 'lib_blog_open', {
      md_i: md_i,
    });
  }

  /** Registra un evento de búsqueda en la sección de publicaciones del blog (cambio de página, cambio de filtro o filtrado manual) */
  trackBlogSearchEvent(filters: any, success: boolean, userId?: number) {
    const md_i = userId ? btoa(userId.toString()) : null;
    gtag('event', 'lib_blog_search', {
      md_i: md_i,
      lib_blog_filters: filters ? JSON.stringify(filters) : null, // Filtros aplicados al realizar la búsqueda de blogs
      search_success: success ? 1 : 0, // Si la consulta tuvo éxito o no
    });
  }

  conversion() {
    gtag('event', 'conversion', {
      send_to: 'AW-11369045437/IK24CNyy_6YZEL27l60q',
      transaction_id: '',
    });
  }

  /** Registra un clic en uno de los usuarios para filtrar estadísticas del despacho */
  trackStatsUserClick(userData: UserData) {
    gtag('event', 'stats_filter_user', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }

  /** Registra un clic en la opción de desglose de asuntos por dimensiones */
  trackStatsDimensionFilter(dimensionName: string, userData: UserData) {
    gtag('event', 'stats_by_dimension', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
      dimension_name: dimensionName,
    });
  }

  /** Registra la creación de un reporte */
  trackReportCreated(userData: UserData) {
    gtag('event', 'report_created', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }

  /** Registra la búsqueda de un reporte y los filtros aplicados */
  trackReportSearchEvent(filters: any, userData: UserData, success: boolean) {
    gtag('event', 'report_search', {
      md_i: btoa(userData.id_users.toString()),
      report_filters: filters ? JSON.stringify(filters) : null, // Filtros aplicados al realizar la búsqueda
      search_success: success ? 1 : 0, // Si la consulta tuvo ningún o no
    });
  }

  /** Registra la edición de un reporte */
  trackEditReportEvent(reportId: number, userData: UserData) {
    gtag('event', 'report_edit', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
      report_id: reportId,
    });
  }

  /** Registra la descarga de un reporte */
  trackDownloadReportEvent(userData: UserData, downloadedFrom: string, reportId?: number) {
    gtag('event', 'report_download', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
      report_id: reportId,
      downloaded_from: downloadedFrom, // Registra si el reporte fue descargado de la sección de reportes (tabla) o desde la vista de previsualización
    });
  }

  /** Registra un click en una de las acciones de la tabla de reportes que no sea la acción de descarga */
  trackReportActionClick(reportId: number, action: string, userData: UserData) {
    gtag('event', 'report_action_click', {
      md_i: btoa(userData.id_users.toString()),
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
      report_action: action,
      report_id: reportId,
    });
  }

  /** Se registra cuando el asistente se cierra desde cualquier método */
  trackAssistantClosed(userId?: number) {
    if (!userId) return;
    gtag('event', 'assistant_closed', {
      md_i: btoa(userId.toString()),
    });
  }

  /** Se registra cuando se envía un mensaje desde el asistente */
  trackAssistantMessageSent(userId: number) {
    if (!userId) return;
    gtag('event', 'assistant_message_sent', {
      md_i: btoa(userId.toString()),
    });
  }

  /** Se registra cuando se crea una conversación desde el asistente */
  trackAssistantThreadCreated(userId: number, threadId: string) {
    if (!userId) return;
    gtag('event', 'assistant_thread_created', {
      md_i: btoa(userId.toString()),
      l_thread_id: threadId,
    });
  }

  /** Se registra cuando se maximiza el asistente */
  trackAssistantMaximized(userId: number) {
    if (!userId) return;
    gtag('event', 'assistant_maximized', {
      md_i: btoa(userId.toString()),
    });
  }

  /** Se registra cuando se minimiza el asistente */
  trackAssistantMinimized(userId: number) {
    if (!userId) return;
    gtag('event', 'assistant_minimized', {
      md_i: btoa(userId.toString()),
    });
  }

  /** Se registra cuando se agrega un documento a una conversación desde el asistente */
  trackAssistantDocumentAdded(userId: number) {
    if (!userId) return;
    gtag('event', 'assistant_document_added', {
      md_i: btoa(userId.toString()),
    });
  }

  /** Se registra cuando se elimina un documento de una conversación desde el asistente */
  trackAssistantDocumentRemoved(userId: number) {
    if (!userId) return;
    gtag('event', 'assistant_document_removed', {
      md_i: btoa(userId.toString()),
    });
  }

  /** Se registra cuando se abre la información del asistente */
  trackAssistantInfoShown(userId: number) {
    if (!userId) return;
    gtag('event', 'assistant_info_shown', {
      md_i: btoa(userId.toString()),
    });
  }

  /** Se registra cuando se cierra la información del asistente */
  trackAssistantInfoHidden(userId: number) {
    if (!userId) return;
    gtag('event', 'assistant_info_hidden', {
      md_i: btoa(userId.toString()),
    });
  }

  /** Se registra cuando se selecciona una acción rápida del asistente (chip) */
  trackAssistantChipSelected(userId: number, chip: string) {
    if (!userId) return;
    gtag('event', 'assistant_chip_selected', {
      md_i: btoa(userId.toString()),
      l_chip: chip,
    });
  }

  /** Registra un click para acceder al calendario y su origen */
  trackCalendarClick(userId: number, origin: string) {
    if (!userId) return;
    gtag('event', 'calendar_click', {
      md_i: btoa(userId.toString()),
      click_origin: origin,
    });
  }

  /** Se registra cuando se realiza una suscripción y se registra un método de pago */
  trackSubscription(subscription: SubscriptionComplete, userData: UserData) {
    if (!userData?.id_users) return;
    gtag('event', 'w_subscription', {
      md_i: btoa(userData.id_users.toString()),
      subscription_id: subscription.stripeObject?.id,
      customer_id: subscription.stripeObject?.customer,
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member,
    });
  }

  /** Registra una visualización de un documento del estudio */
  trackStudioDocumentOpened(documentId: number, userData: UserData) {
    gtag('event', 'estudio_visualize_doc', {
      md_i: btoa(userData.id_users.toString()), // ID en base64
      w_workspace_id: userData.idWorkspace || userData.id_workspace_member, // ID del despacho
      sf_id: documentId, // Id del documento
    });
  }

   /**
   * Rastrear creación de documentos
   */
   trackDocEditorDocumentCreated(userId: number, workspaceId: number, documentName: string) {
    gtag('event', 'doc_editor_document_created', {
      md_i: btoa(userId.toString()), // ID del usuario en base64
      workspace_id: workspaceId, // ID del despacho
      document_name: documentName, // Nombre del documento creado
    });
  }

  /**
   * Rastrear edición de documentos
   */
  trackDocEditorDocumentEdited(userId: number, workspaceId: number, documentId: number) {
    gtag('event', 'doc_editor_document_edited', {
      md_i: btoa(userId.toString()),
      workspace_id: workspaceId,
      document_id: documentId,
    });
  }

  /**
   * Rastrear guardado de documentos
   */
  trackDocEditorDocumentSaved(userId: number, workspaceId: number, documentId: number) {
    gtag('event', 'doc_editor_document_saved', {
      md_i: btoa(userId.toString()),
      workspace_id: workspaceId,
      document_id: documentId,
    });
  }

  /**
   * Rastrear descarga de documentos
   */
  trackDocEditorDocumentDownloaded(userId: number, workspaceId: number, documentId: number) {
    gtag('event', 'doc_editor_document_downloaded', {
      md_i: btoa(userId.toString()),
      workspace_id: workspaceId,
      document_id: documentId,
    });
  }

  /**
   * Rastrear exportación de documentos
   */
  trackDocEditorDocumentExported(userId: number, workspaceId: number, documentId: number) {
    gtag('event', 'doc_editor_document_exported', {
      md_i: btoa(userId.toString()),
      workspace_id: workspaceId,
      document_id: documentId,
    });
  }
}
