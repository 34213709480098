<div class="py-2 px-5 flex border-b border-slate-200">
  <!-- Botón para volver a la lista principal -->
  <button
    class="grid size-[2.375rem] shrink-0 place-content-center rounded enabled:hover:bg-slate-300/60 enabled:hover:shadow-sm enabled:hover:text-slate-500 disabled:opacity-50 text-slate-400"
    [ngClass]="{'mr-1':  !selectedDossier}" (click)="returnToList()"
    [disabled]="!selectedDossier && !selectedCollection" [ngxTippy]="'Volver a Asuntos'">
    <i class="fa-solid fa-arrow-left"></i>
  </button>
  <!-- Botón para cambiar el tipo de documentos del asunto entre documentos o legislación -->
  <button
    class="grid size-[2.375rem] shrink-0 place-content-center rounded enabled:hover:bg-slate-300/60 enabled:hover:shadow-sm enabled:hover:text-slate-500 disabled:opacity-50 text-slate-400 mr-1"
    [menuTriggerFor]="dossierFileType" *ngIf="selectedDossier && selectedDossier.canEdit"
    ngxTippy="Cambiar Tipo de Documentos">
    <i class="fa-solid fa-list-ul"></i>
  </button>
  <!-- Input de búsqueda -->
  <div class="relative grow">
    <label for="simple-doc-picker-search" class="sr-only" translate="SEARCH_PLACEHOLDER_STRING"></label>
    <input (keydown.enter)="$event.preventDefault();onSearch()" id="simple-doc-picker-search"
      [formControl]="searchControl" class="form-input w-full !pr-14" type="search"
      [placeholder]="getInputPlaceholder()">
    <div class="absolute flex gap-2 right-3 top-2/4 -translate-y-2/4">
      <button type="submit" *ngIf="searchControl.value" aria-label="Limpiar" ngxTippy data-tippy-content="Limpiar"
        (click)="clearSearch()" class="px-2 border-r border-slate-200" [tippyProps]="{placement: 'top'}">
        <i class="fa-duotone error-icon fa-xmark text-[0.8rem]" style="--fa-secondary-opacity: 0.8 !important"></i>
      </button>
      <button (click)="onSearch()" type="submit" aria-label="Buscar" ngxTippy data-tippy-content="Clic aquí para buscar"
        [tippyProps]="{placement: 'top'}">
        <i class="fa-duotone fa-magnifying-glass secondary-icon text-[0.8rem]"></i>
      </button>
    </div>
  </div>
</div>

<!-- Listado de Asuntos -->
<div class="bg-pearl-100 divide-y divide-slate-200 overflow-y-auto relative"
  *ngIf="!selectedDossier && !(loading | async) && dossiers[0]" infiniteScroll [infiniteScrollDistance]="0"
  [infiniteScrollThrottle]="0" [scrollWindow]="false" [immediateCheck]="true" [alwaysCallback]="true"
  (scrolled)="nextPage()">
  <div class="group flex gap-2 py-2 items-center px-5 w-full hover:bg-indigo-50"
    [ngClass]="{'bg-indigo-50': docPickerService.isSelected(dossier)}"
    *ngFor="let dossier of dossiers; let i = index; trackBy:trackByFn">
    <button type="button" (click)="selectDossier(dossier)" class="flex-1 flex items-center gap-2">
      <div class="size-6 rounded-full grid place-content-center justify-center shrink-0"
        [ngClass]="dossier.fkIdStatusDossier | dossierIconStatus">
        <i class="fa-regular" style="font-size: 0.8rem;" [ngClass]="dossier.fkIdStatusDossier | dossierStatusIcon"></i>
      </div>
      <div class="flex flex-col min-w-0 items-start">
        <span class="font-medium text-slate-600 truncate text-sm">{{ dossier.name | titlecase }}</span>
        <div class="text-xs text-slate-600">{{ dossier.numberDossier }}</div>
      </div>
    </button>

    <div class="ml-auto flex items-center"
      [ngClass]="docPickerService.isSelected(dossier) ? 'visible' : 'invisible group-hover:visible'">
      <button (click)="toggleDossierSelection(dossier)"
        [ngxTippy]="docPickerService.isSelected(dossier) ? 'Eliminar de la conversación' : 'Agregar Asunto'"
        [ngClass]="docPickerService.isSelected(dossier) ? 'bg-indigo-100 text-indigo-600' : ''"
        class="size-7 rounded-full hover:bg-indigo-100 grid place-content-center">
        <i class="fa-regular fa-folder-plus"
          [ngClass]="docPickerService.isSelected(dossier) ? 'fa-check' : 'fa-folder-plus'"></i>
      </button>
      <button (click)="selectDossier(dossier)"
        class="size-7 rounded-full hover:bg-indigo-100 grid place-content-center">
        <i class="fa-solid fa-chevron-right text-[0.9rem]"></i>
      </button>
    </div>
  </div>


</div>

<!-- Empty state si no hay asuntos -->
<div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-3"
  *ngIf="!dossiers[0] && !selectedDossier && !(loading | async) && !(loadingFolder | async)">
  <div class="bg-indigo-100 size-20 grid place-content-center rounded-full">
    <i class="fa-solid fa-folder-open text-[2rem]"></i>
  </div>
  <span class="text-center font-medium">No encontramos asuntos para mostrar</span>
</div>

<!-- Listado de carpetas y documentos del asunto seleccionado -->
<div class="pl-5 pr-4 overflow-auto"
  *ngIf="selectedDossier && !(loading | async) && selectedDossierFileType == 'documents'">
  <app-simple-storage-object-data [storageObjects]="storageObjects" />
</div>

<!-- Listado de search_objects cuando hay una colección seleccionada -->
<div class="pl-5 pr-4 overflow-auto"
  *ngIf="selectedDossier && !(loading | async) && !(loadingCollectionsData | async) && selectedDossierFileType == 'legislation' && filteredSearchObjects[0]">
  <app-simple-search-object-data [objects]="filteredSearchObjects" (onSelect)="onSearchObjectClick($event)" />
</div>

<!-- Empty state si no hay colecciones -->
<div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-3"
  *ngIf="selectedDossierFileType == 'legislation' && !searchObjects[0] && selectedDossier && selectedCollection && !(loading | async) && !(loadingCollectionsData | async)">
  <div class="bg-indigo-100 size-20 grid place-content-center rounded-full">
    <i class="fa-solid fa-folder-open text-[2rem]"></i>
  </div>
  <span class="text-center font-medium">No encontramos resultados dentro de esta colección</span>
</div>

<!-- Loader de asuntos o colecciones -->
<div class="flex flex-col gap-2 animate-pulse pointer-events-none p-5"
  *ngIf="(loading | async) || ((loadingFolder | async) && !selectedFolder) || (loadingCollectionsData | async)">
  <div class="flex gap-2" *ngFor="let _ of [].constructor(5);">
    <div class="size-8 shrink-0 bg-gray-200 rounded-full"></div>
    <div class="flex flex-col gap-1 *:bg-gray-200 grow">
      <div class="w-9/12 h-3 rounded"></div>
      <div class="w-5/12 h-3 rounded"></div>
    </div>
  </div>
</div>
<div *ngIf="(scrolling | async)" class="flex items-center justify-center gap-1 p-4 text-slate-600 pointer-events-none">
  <i class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i>
  <span>Cargando...</span>
</div>

<!-- Menu para cambiar de tipo de documento de asuntos -->
<ng-template #dossierFileType>
  <div
    class="mt-1 bg-pearl-100 border border-slate-200 rounded shadow-lg w-[90svw] py-1.5 animate__animated animate__fadeIn animate__faster max-w-64"
    cdkMenu>
    <button (click)="changeDossierFileType('documents')"
      class="group app-menu-option justify-between gap-2 aria-checked:bg-indigo-50"
      [cdkMenuItemChecked]="selectedDossierFileType === 'documents'" cdkMenuItemRadio>
      Documentos Anexados
      <i class="fa-solid fa-check text-indigo-500 invisible group-aria-checked:visible"></i>
    </button>
    <button (click)="changeDossierFileType('legislation')"
      class="group app-menu-option justify-between gap-2 aria-checked:bg-indigo-50" *ngIf="selectedDossier?.canEdit"
      [cdkMenuItemChecked]="selectedDossierFileType === 'legislation'" cdkMenuItemRadio #radio>
      Legislación
      <i class="fa-solid fa-check text-indigo-500 invisible group-aria-checked:visible"></i>
    </button>
  </div>
</ng-template>