import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ActivityFile } from 'src/app/features/law-firm/interfaces/activity.interfaces';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { ExtToIconPipe } from 'src/app/features/law-firm/pipes/ext-to-icon.pipe';
import { FileSizePipe } from 'src/app/shared/pipes/get-file-size.pipe';
import { SelectDossierTabComponent } from '../select-dossier-tab/select-dossier-tab.component';
import { SimpleDocPickerService } from '../simple-doc-picker.service';
import { DocPickerSelection } from '../simple-doc-picker.component';

@Component({
    selector: 'app-simple-storage-object-data',
    standalone: true,
    imports: [
        CommonModule,
        ExtToIconPipe,
        TranslateModule,
        FileSizePipe,
        NgxTippyModule,
    ],
    templateUrl: './simple-storage-object-data.component.html',
    host: {
        class: 'contents',
    },
    providers: [FileSizePipe],
})
export class SimpleStorageObjectDataComponent {
    private readonly fileSizePipe = inject(FileSizePipe);
    readonly docPickerService = inject(SimpleDocPickerService);
    readonly container = inject(SelectDossierTabComponent);

    @Input({ required: true }) storageObjects: DocPickerSelection<StorageObject>[] = [];
    @Input() parentId?: number;

    trackByFn(index: any) {
        return index;
    }

    isLoading() {
        return (
            this.container.loading.value || this.container.loadingFolder.value
        );
    }

    isTooBig(file: ActivityFile) {
        return (
            this.container.sizeLimit && +file.size > this.container.sizeLimit
        );
    }

    getTooBigTooltip(file?: ActivityFile): string | null {
        if (file && this.container.sizeLimit && this.isTooBig(file)) {
            const sizeLimit = this.fileSizePipe.transform(
                this.container.sizeLimit
            );
            return `<p class='text-pretty'>El documento excede el tamaño máximo permitido de ${sizeLimit}</p>`;
        }
        return null;
    }
}
