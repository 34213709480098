import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneChangeEvent, NgxDropzoneModule } from 'ngx-dropzone';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { ALERT_TOAST_DEFAULTS } from 'src/app/features/calendar/constants/alert-defaults.constants';
import { STORAGE_OBJECT_TYPES } from 'src/app/features/law-firm/constants/storage-object-types.constants';
import { ExtToIconPipe } from 'src/app/features/law-firm/pipes/ext-to-icon.pipe';
import { FileSizePipe } from 'src/app/shared/pipes/get-file-size.pipe';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SimpleDocPickerService } from '../simple-doc-picker.service';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-upload-file-tab',
    standalone: true,
    imports: [
        CommonModule,
        NgxDropzoneModule,
        TranslateModule,
        ExtToIconPipe,
        FileSizePipe,
        NgxTippyModule,
    ],
    templateUrl: './upload-file-tab.component.html',
})
export class UploadFileTabComponent {
    private readonly api = inject(UisrApiServiceV2);
    private readonly docPickerService = inject(SimpleDocPickerService);

    saving = new BehaviorSubject(false);

    /** Archivos a subir */
    files: File[] = [];
    @Input() conversationId: any;

    /** Verifica el tamaño de los archivos antes de agregarlos a la lista de documentos a subir */
    onSelect(event: NgxDropzoneChangeEvent) {
        // Verificar el tamaño del archivo a subir y el tamaño de todos los archivos
        if (
            event.addedFiles[0].size > environment.maxFileSize ||
            this.files.reduce((acc, file) => acc + file.size, 0) +
                event.addedFiles[0].size >
                environment.maxFileSize
        ) {
            Swal.fire({
                ...ALERT_TOAST_DEFAULTS,
                icon: 'error',
                text: 'El tamaño de los archivos supera el límite permitido de 3GB.',
            });
            return;
        }

        if (event.addedFiles[0]) {
            this.uploadFile(event.addedFiles[0]);
        } else {
            Swal.fire({
                ...ALERT_TOAST_DEFAULTS,
                icon: 'error',
                text: 'El documento no es valido',
            });
        }
    }

    /** Sube un archivo al servidor */
    uploadFile(file: File) {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('folder', 'lawfirm');
        formData.append('type_id', STORAGE_OBJECT_TYPES.DOCUMENT.toString());
        formData.append('amparo_file', 'true');

        if (this.conversationId) {
            formData.append('conversation_id', this.conversationId.toString());
        }

        this.api
            .post(RESOURCES.insertStudioFiles, formData)
            .pipe(loadingState(this.saving), untilDestroyed(this))
            .subscribe({
                next: (res) => {
                    if (res.success) {
                        const item = this.docPickerService.buildSelectableItems(
                            res.data
                        )[0];
                        this.docPickerService.updateSelection(item);

                        this.files.push(file);
                    }
                },
            });
    }

    /** Elimina un archivo de la lista de archivos a subir */
    onRemove(event: any) {
        this.files.splice(this.files.indexOf(event), 1);
    }
}
