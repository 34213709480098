import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { MemberInfo } from 'src/app/features/law-firm/interfaces/activity.interfaces';
import Swal from 'sweetalert2';
import { LoadingElementDirective } from '../../directives/loading-element.directive';
import { loadingState } from '../../operators/loading-state.operator';
import { UisrApiServiceV2 } from '../../services/uisr-api.service-v2';
import { UserGroupMember } from '../../interfaces/user-group.interface';
import { ALERT_TOAST_DEFAULTS } from 'src/app/features/calendar/constants/alert-defaults.constants';

@UntilDestroy()
@Component({
  selector: 'app-manage-user-group',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxTippyModule,
    NgSelectModule,
    LoadingElementDirective,
  ],
  templateUrl: './manage-user-group.component.html',
})
export class ManageUserGroupComponent implements OnInit {
  action = signal<string>('create');
  dialogData? = inject(DIALOG_DATA, { optional: true });
  dialogRef? = inject(DialogRef, { optional: true });
  userData?: UserData;
  members: any[] = [];
  form: FormGroup;
  formBuilder = inject(FormBuilder);
  loading = new BehaviorSubject(false);
  resources = RESOURCES;

  private apiService = inject(UisrApiServiceV2);
  private store = inject(Store);

  get controls() {
    return this.form.controls;
  }

  constructor() {
    this.form = this.formBuilder.group({
      idUserGroup: [null],
      name: [null, [Validators.required]],
      description: [null],
      members: [null],
      config: new FormGroup({
        readDossier: new FormControl(false),
        writeDossier: new FormControl(false),
      }),
    });

    this.subscribeToWriteDossier();

    this.store
      .pipe(select(UserDataFull), untilDestroyed(this))
      .subscribe((data) => {
        this.userData = data;
      });
  }

  subscribeToWriteDossier() {
    (this.form.controls['config'] as FormGroup).controls[
      'writeDossier'
    ].valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe({
        next: (value) => {
          if (value) {
            (this.form.controls['config'] as FormGroup).controls[
              'readDossier'
            ].disable();
            (this.form.controls['config'] as FormGroup).controls[
              'readDossier'
            ].setValue(true);
          } else {
            (this.form.controls['config'] as FormGroup).controls[
              'readDossier'
            ].enable();
          }
        },
      });
  }

  ngOnInit(): void {
    if (!this.dialogData) {
      this.getMembers();
    } else {
      this.setOriginalData();
    }
  }

  setOriginalData() {
    let existingMembers = [];
    this.members = this.dialogData.members;
    const existingGroup = this.dialogData.group;
    if (existingGroup) {
      this.action.set('edit');
      const existingIds = existingGroup.members.map(
        (m: UserGroupMember) => +m.memberId
      );
      if (existingIds && existingIds.length > 0) {
        existingMembers = this.members.filter((m: any) =>
          existingIds.includes(+m.idMembersWorkspace)
        );
      }

      this.form.patchValue({ ...existingGroup, members: existingMembers });
    }
  }

  getMembers() {
    const id = this.userData?.id_workspace_member || this.userData?.idWorkspace;
    if (id) {
      let filter = {
        bolDelete: false,
        bolActive: true,
        fkIdWorkSpace: id,
      };
      this.apiService
        .get(this.resources.members, filter)
        .pipe(loadingState(this.loading), untilDestroyed(this))
        .subscribe({
          next: (res: APIResponse<MemberInfo[]>) => {
            this.members = res.data
              .map((m) => ({
                ...m,
                label: m.fullName,
              }))
              .filter((member) => member.label);
          },
        });
    }
  }

  onSave() {
    const data = structuredClone(this.form.getRawValue());

    data.members = data.members.map((m: any) => ({
      memberId: m.idMembersWorkspace,
      userId: m.fkIdUsersMember,
      groupId: data.idUserGroup,
    }));

    if (this.action() == 'create') {
      this.apiService
        .post(this.resources.userGroup, data, null, ['button'])
        .pipe(loadingState(this.loading), untilDestroyed(this))
        .subscribe({
          next: (res: APIResponse<any>) => {
            if (this.dialogRef) {
              this.dialogRef.close(res.data);
            }
            Swal.fire({
              ...ALERT_TOAST_DEFAULTS,
              text: 'Se ha creado el grupo correctamente',
            });
          },
        });
    } else {
      this.apiService
        .patch(this.resources.userGroup, data, null, ['button'])
        .pipe(loadingState(this.loading), untilDestroyed(this))
        .subscribe({
          next: (res: APIResponse<any>) => {
            if (this.dialogRef) {
              this.dialogRef.close(res.data);
            }

            Swal.fire({
              ...ALERT_TOAST_DEFAULTS,
              text: 'Se ha editado el grupo correctamente',
            });
          },
        });
    }
  }
}
