import { Injectable } from '@angular/core';
import { DossierDTO } from 'src/app/features/law-firm/interfaces/dossier.interfaces';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { StudioFiles } from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { SearchObject } from '../../interfaces/search-object.types';
import { DocPickerSelection } from './simple-doc-picker.component';

@Injectable({
    providedIn: 'root',
})
export class SimpleDocPickerService {
    /** Son los documentos que ya se habían agregado a la conversación antes de abrir el modal */
    existing: DocPickerSelection[] = [];
    /** Son los documentos que se van a agregar a la conversación, el usuario los selecciona mientras interactúa con el modal */
    selection: DocPickerSelection[] = [];

    /** Verifica si el elemento existía antes de abrir el modal, por lo tanto ya está agregado a la conversación */
    isExisting(item: DocPickerSelection) {
        return this.existing.some(
            (s) => s.reference && s.reference == item.reference
        );
    }

    /** Verifica si un elemento existe en el arreglo de selección usando su referencia */
    isSelected(item: DocPickerSelection) {
        return this.selection.some(
            (s) => s.reference && s.reference == item.reference
        );
    }

    /** Obtiene el index en el arreglo de selección de un elemento usando su referencia */
    getIndex(item: DocPickerSelection) {
        return this.selection.findIndex(
            (s) => s.reference && s.reference == item.reference
        );
    }

    /** Verifica si el elemento existe en la selección, si existe lo elimina y si no lo agrega */
    updateSelection(item: DocPickerSelection) {
        const index = this.getIndex(item);

        if (index >= 0) {
            this.selection.splice(index, 1);
        } else {
            this.selection.push(item);
        }
    }

    /**
     * Construye un elemento seleccionable agregando su referencia y tipo en base al origen (estudio, colección, dossier, etc).
     * Extrae la clave (ID) según el tipo del elemento.
     * @param items Arreglo de elementos para construir la referencia.
     * @returns El arreglo de elementos seleccionables con sus referencias y tipos.
     */
    buildSelectableItems(
        items: StudioFiles[] | StorageObject[] | DossierDTO[] | SearchObject[]
    ): DocPickerSelection[] {
        if (!items || !Array.isArray(items)) {
            throw new Error(
                'Entrada inválida: los elementos deben ser un arreglo.'
            );
        }

        return items.map((item) => {
            let type: string;
            let key: string | number;

            // Determina el tipo y la clave en base a las propiedades del objeto
            if ('studio_file_id' in item) {
                type = 'studioFile';
                key = item.studio_file_id;
            } else if ('id' in item) {
                type = 'dossierFile';
                key = item.file?.idActivityFile || (item.id as number);
            } else if ('idDossier' in item) {
                type = 'dossier';
                key = item.idDossier;
            } else if ('_id' in item) {
                type = 'searchObject';
                key = `${item._id}-${item._index}`;
            } else {
                throw new Error('No se pudo determinar el tipo del elemento.');
            }

            // Genera la referencia concatenando el tipo y la clave
            const reference = `${type}-${key}`;

            return {
                ...item,
                reference, // Incluye la referencia generada
                type, // Incluye el tipo determinado
            };
        });
    }
}
