import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    Subject,
    switchMap,
} from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { PAGINATION_CONFIG } from 'src/app/features/law-firm/constants/pagination.constants';
import { ClientFolder } from 'src/app/features/law-firm/interfaces/client.interfaces';
import { loadingState } from '../../operators/loading-state.operator';
import { UisrApiServiceV2 } from '../../services/uisr-api.service-v2';

@UntilDestroy()
@Component({
    selector: 'app-client-folder-selector',
    templateUrl: './client-folder-selector.component.html',
})
export class ClientFolderSelectorComponent {
    private readonly api = inject(UisrApiServiceV2);
    private readonly dialogData = inject(DIALOG_DATA, { optional: true });
    private readonly store = inject(Store);
    readonly dialogRef = inject(DialogRef, { optional: true });

    scrolling = new BehaviorSubject(false);
    loading = new BehaviorSubject(false);
    loadingFolder = new BehaviorSubject(false);
    folders: ClientFolder[] = [];
    userData = {} as UserData;
    selection = new Set<ClientFolder>();
    selectedFolder?: ClientFolder;
    searchControl: FormControl<null | string> = new FormControl(null);
    existingIds: number[] = [];
    filter: any = {};
    filterSubject = new Subject<any>();
    pagination = structuredClone(PAGINATION_CONFIG);

    @Input() multiple = false;
    @Input() embedded = false;

    @Output() selectionChange = new EventEmitter<Set<ClientFolder>>();

    ngOnInit() {
        this.store
            .pipe(select(UserDataFull), untilDestroyed(this))
            .subscribe((data) => {
                this.userData = data;
            });

        if (this.dialogData) {
            this.multiple = this.dialogData.multiple || false;
        }

        this.subscribeToFilter();
        this.applyFilter({ pageNumber: 1, pageSize: 10 });
    }

    /** Suscribirse al input de búsqueda para realizar la búsqueda */
    subscribeToSearch() {
        this.searchControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(300),
                untilDestroyed(this)
            )
            .subscribe({
                next: () => {
                    this.onSearch();
                },
            });
    }

    onSearch() {
        this.applyFilter({
            pageNumber: 1,
            searchValue: this.searchControl.value || undefined,
        });
    }

    subscribeToFilter() {
        this.filterSubject
            .pipe(
                switchMap((filter: any) => {
                    this.pagination.itemsPerPage = filter.pageSize || 10;
                    this.pagination.currentPage = filter.pageNumber || 1;

                    return this.api
                        .get(RESOURCES.clients, filter)
                        .pipe(loadingState(this.loading));
                }),
                untilDestroyed(this)
            )
            .subscribe({
                next: (res) => {
                    this.folders = res.data;
                    this.pagination.totalItems = res.total || 0;
                },
            });
    }

    applyFilter(filter: any) {
        this.filter = {
            ...this.filter,
            ...filter,
            bolClient: true,
            idWorkspace:
                this.userData.idWorkspace || this.userData.id_workspace_member,
        };

        this.filter = UisrTools.removeEmptyProperties(this.filter);

        this.filterSubject.next(this.filter);
    }

    /** Limpia el input de búsqueda y realiza la búsqueda de asuntos con los filtros por defecto */
    clearSearch() {
        this.searchControl.setValue(null);
        this.onSearch();
    }

    /** Al hacer click en una carpeta, se agrega o elimina del arreglo de elementos seleccionados */
    onFolderClick(folder: ClientFolder) {
        if (this.selection.has(folder)) {
            this.selection.delete(folder);
        } else if (this.multiple) {
            this.selection.add(folder);
        } else {
            this.selection.clear();
            this.selection.add(folder);
        }

        this.selectionChange.emit(this.selection);
    }

    /** Se ejecuta cuando el usuario confirma la selección, emite la selección de carpetas actual*/
    onSubmit() {
        // Listado de todas las carpetas seleccionadas
        const folders: any[] = Array.from(this.selection);

        this.dialogRef?.close(folders);
    }

    /** Buscar la siguiente página de asuntos al hacer scroll */
    nextPage() {
        if (
            this.pagination.totalItems &&
            this.pagination.totalItems > this.folders.length
        ) {
            this.filter.pageNumber = this.pagination.currentPage + 1;
            this.api
                .get(RESOURCES.clients, this.filter)
                .pipe(loadingState(this.scrolling), untilDestroyed(this))
                .subscribe({
                    next: (res: APIResponse<ClientFolder[]>) => {
                        this.folders.push(...res.data);
                        this.pagination.currentPage =
                            this.filter.pageNumber || 1;
                        this.pagination.itemsPerPage =
                            this.filter.pageSize || 10;
                        this.pagination.totalItems = res.total || 0;
                    },
                });
        }
    }
}
