import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { StudioFiles } from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { ExtToIconPipe } from 'src/app/features/law-firm/pipes/ext-to-icon.pipe';
import { FileSizePipe } from 'src/app/shared/pipes/get-file-size.pipe';
import { SelectStudioFileTabComponent } from '../select-studio-file-tab.component';
import { STORAGE_OBJECT_TYPES } from 'src/app/features/law-firm/constants/storage-object-types.constants';
import { DocPickerSelection } from '../../simple-doc-picker.component';
import { SelectDossierTabComponent } from '../../select-dossier-tab/select-dossier-tab.component';
import { SimpleDocPickerService } from '../../simple-doc-picker.service';

@Component({
    selector: 'app-studio-item-data',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        NgxTippyModule,
        FileSizePipe,
        ExtToIconPipe,
    ],
    templateUrl: './studio-item-data.component.html',
    host: {
        class: 'contents',
    },
    providers: [FileSizePipe],
})
export class StudioItemDataComponent {
    private readonly fileSizePipe = inject(FileSizePipe);
    readonly docPickerService = inject(SimpleDocPickerService);
    readonly container = inject(SelectStudioFileTabComponent, {
        skipSelf: true,
    });

    readonly documentType = STORAGE_OBJECT_TYPES.DOCUMENT;

    /** Listado de carpetas/documentos del asunto */
    @Input({ required: true }) items: DocPickerSelection<StudioFiles>[] = [];
    @Input() parentId?: number;

    trackByFn(index: any) {
        return index;
    }

    isLoading() {
        return (
            this.container.loading.value || this.container.loadingFolder.value
        );
    }

    isTooBig(file: StudioFiles) {
        return (
            file.size &&
            this.container.sizeLimit &&
            +file.size > this.container.sizeLimit
        );
    }

    getTooBigTooltip(file?: StudioFiles): string | null {
        if (file && this.container.sizeLimit && this.isTooBig(file)) {
            const sizeLimit = this.fileSizePipe.transform(
                this.container.sizeLimit
            );
            return `<p class='text-pretty'>El archivo excede el tamaño máximo permitido de ${sizeLimit}</p>`;
        }
        return null;
    }
}
