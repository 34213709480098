import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ClientFolder, Clients } from 'src/app/features/law-firm/interfaces/client.interfaces';
import { ClientFolderSelectorComponent } from '../client-folder-selector.component';

@Component({
    selector: 'app-client-folder-data',
    standalone: true,
    imports: [CommonModule, TranslateModule, NgxTippyModule],
    templateUrl: './client-folder-data.component.html',
    host: {
        class: 'contents',
    },
})
export class ClientFolderDataComponent {
    container = inject(ClientFolderSelectorComponent, {
        optional: true,
        skipSelf: true,
    });
    @Input({ required: true }) folders: ClientFolder[] = [];
    @Input() parentId?: number;

    trackByFn(index: any, item: any) {
        return index;
    }

    isLoading() {
        return this.container?.loading.value;
    }
}
