import Step from 'shepherd.js/src/types/step';

export const CREATE_REPORT_STEPS: Step.StepOptions[] = [
    {
        attachTo: {
            element:
                document.getElementById('report-type-select') ||
                '#report-type-select',
            on: 'bottom',
        },
        scrollTo: true,
        title: 'Selecciona el Tipo de Reporte',
        text: `<p>El primer paso es elegir el tipo de reporte que deseas crear. Esto determinará las columnas y configuraciones disponibles. Actualmente podrás crear reportes de Asuntos.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('template-select') ||
                '#template-select',
            on: 'bottom',
        },
        title: 'Selecciona una Plantilla',
        text: `<p>Puedes usar una plantilla predefinida para agilizar la configuración del reporte. Selecciona una de la lista o configura las columnas manualmente.</p>`,
    },
    {
        attachTo: {
            element: document.getElementById('columns-list') || '#columns-list',
            on: 'right',
        },
        title: 'Selecciona las Columnas del Reporte',
        text: `<p>Marca las casillas para incluir las columnas que deseas en tu reporte. También puedes reorganizarlas arrastrándolas según sea necesario.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('report-name-input') ||
                '#report-name-input',
            on: 'top',
        },
        title: 'Asigna un Nombre al Reporte',
        text: `<p>Ingresa un nombre para identificar el reporte. Este campo es obligatorio.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('report-description-input') ||
                '#report-description-input',
            on: 'top',
        },
        title: 'Agrega una Descripción',
        text: `<p>Proporciona una descripción o detalle adicional sobre el propósito del reporte.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('visibility-checkbox') ||
                '#visibility-checkbox',
            on: 'left',
        },
        title: 'Configura la Visibilidad',
        text: `<p>Marca esta casilla si deseas que el reporte sea visible para todos los miembros del despacho. Deja sin marcar para hacerlo privado.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('preview-section') ||
                '#preview-section',
            on: 'top',
        },
        title: 'Vista Previa del Reporte',
        text: `<p>A medida que configures las opciones, aquí podrás ver una vista previa de cómo se verá el reporte con los datos seleccionados.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('filters-button') || '#filters-button',
            on: 'top',
        },
        title: 'Filtra la Información',
        text: `<p>Usa la opción de filtros para personalizar los datos que aparecerán en el reporte. Aplica condiciones para obtener resultados específicos.</p>`,
    },
    {
        attachTo: {
            element:
                document.getElementById('download-button') ||
                '#download-button',
            on: 'top',
        },
        title: 'Descargar el Reporte',
        text: `<p>Cuando estés satisfecho con el contenido, usa este botón para descargar el reporte en el formato disponible.</p>`,
    },
    {
        attachTo: {
            element: document.getElementById('save-button') || '#save-button',
            on: 'top',
        },
        title: 'Guardar el Reporte',
        text: `<p>Haz clic en el botón "Guardar Cambios" para finalizar la creación y guardar el reporte.</p>`,
    },
];
