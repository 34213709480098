import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { DateTime } from 'luxon';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';
import { AssistantChatService } from '../../services/assistant-chat.service';

@Component({
  selector: 'app-chat-threads',
  templateUrl: './chat-threads.component.html',
  styleUrls: ['./chat-threads.component.scss'],
})
export class ChatThreadsComponent implements AfterViewInit {
  tutorialService = inject(AppTutorialService);

  @ViewChild('threadsWrapper') private threadsWrapper!: ElementRef;

  constructor(public assistantChatService: AssistantChatService) {
    this.assistantChatService.threadsComponent = this;
  }

  ngAfterViewInit(): void {
    this.assistantChatService.fetchThreads();
  }

  /** Comprobar si el listado de conversaciones debe agregar automáticamente mas conversaciones */
  checkThreadsScroll() {
    const container = this.threadsWrapper.nativeElement;

    if (
      container.scrollHeight <= container.clientHeight &&
      !this.assistantChatService.loadingStates.loadingThreads.value
    ) {
      this.assistantChatService.fetchNextThreads();
    }
  }

  /**
   * Calcular si el thread actual está en un día diferente con respecto al anterior
   */
  isNewDayThread(actualDate: string, previousDate: string) {
    // Prevenir error si no existe la fecha anterior
    if (!previousDate) {
      return {
        condition: true, // Mostrar fecha si no hay thread anterior
        date: DateTime.fromISO(actualDate)
          .setLocale('es')
          .toFormat("cccc, d 'de' LLLL"),
        time: DateTime.fromISO(actualDate)
          .setLocale('en')
          .toLocaleString(DateTime.TIME_SIMPLE),
      };
    }

    // Convertir las fechas a objetos DateTime
    const actualDateObj = DateTime.fromISO(actualDate).startOf('day');
    const previousDateObj = DateTime.fromISO(previousDate).startOf('day');

    // Comprobar si es un día diferente
    const isDifferentDay = actualDateObj < previousDateObj;

    // Formatear la fecha y la hora si es un día nuevo
    const formattedDate = actualDateObj
      .setLocale('es')
      .toFormat("cccc, d 'de' LLLL");
    const formattedTime = DateTime.fromISO(actualDate)
      .setLocale('en')
      .toLocaleString(DateTime.TIME_SIMPLE);

    return {
      condition: isDifferentDay,
      date: formattedDate,
      time: formattedTime,
    };
  }
}
