import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  interval,
  lastValueFrom,
  Subject,
  takeUntil,
} from 'rxjs';
import { ALERT_TOAST_DEFAULTS } from 'src/app/features/calendar/constants/alert-defaults.constants';
import { AssistantSettings } from 'src/app/shared/interfaces/assistant-settings.types';
import { FeatureAccess } from 'src/app/shared/interfaces/feature-access.types';
import { FeatureConfig } from 'src/app/shared/interfaces/feature-config.types';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal from 'sweetalert2';
import { RESOURCES } from '../constants/resource-service.constants';
import { MenuItem } from '../interfaces/menu-item.interface';
import { AccessToken, Permission } from '../models/user-data';
import { Logout, SaveToken } from '../reducer/user-data/user-data.actions';
import { JsEncode } from '../utils/js-encode';
import { DialogService } from './dialog.service';

@Injectable({
    providedIn: 'root',
})
export class UisrAuthService {
    private readonly subscriptionService = inject(SubscriptionService);
    private readonly dialog = inject(DialogService);
    private readonly api = inject(UisrApiServiceV2);
    private readonly store = inject(Store);
    private readonly router = inject(Router);
    private readonly translate = inject(TranslateService);
    private readonly jwtHelper = inject(JwtHelperService);

    prefix = 'keep';
    permissions = new BehaviorSubject<Permission[]>([]);
    menuOptions = new BehaviorSubject<MenuItem[]>([]);
    assistantSettings = new BehaviorSubject<AssistantSettings | null>(null);
    featureConfig = new BehaviorSubject<FeatureConfig[] | null>(null);
    featureAccess = new BehaviorSubject<FeatureAccess | null>(null);
    resources = RESOURCES;

    private _tokenExpirationTime: number | null = null;
    private _refreshToken: string | null = null;
    private _unsubscribe = new Subject<void>();

    get refreshToken(): string | null {
        return this._refreshToken;
    }

    set refreshToken(value: string | null) {
        if (value) {
            this._refreshToken = value;
        }
    }

    get tokenExpirationTime(): number | null {
        return this._tokenExpirationTime;
    }

    set tokenExpirationTime(value: number | null) {
        if (value) {
            this._tokenExpirationTime = value;
        }
    }

    constructor() {
        this.refreshToken = localStorage.getItem('refreshToken');
        const permissionsRaw = localStorage.getItem('permissions');
        if (permissionsRaw) {
            this.permissions.next(JSON.parse(permissionsRaw));
        }
    }

    /** Da inicio al loop para verificar cada 5 segundos la condición de validez del token */
    async startValidationLoop() {
        this._unsubscribe = new Subject();

        if (await this.executeTokenValidation()) {
            interval(5000)
                .pipe(takeUntil(this._unsubscribe))
                .subscribe(() => {
                    this.executeTokenValidation();
                });
        }
    }

    async sidebarPreferences() {
        const rawUserData = localStorage.getItem('UserData');
        if (rawUserData) {
            const userData = JSON.parse(JsEncode.decrypt(rawUserData));
            if (userData && userData.id_users) {
                this.api
                    .get(this.resources.sidebarPreferences)
                    .pipe(takeUntil(this._unsubscribe))
                    .subscribe((data: any) => {
                        this.menuOptions.next(data.data);
                    });
            }
        }
    }

    async executeTokenValidation(): Promise<boolean> {
        const currentUrl = this.router.url;
        if (this.isValidRefreshToken()) {
            if (this.isTokenAboutToExpire()) {
                // Realiza la solicitud de un nuevo token si está por expirar
                return await this.requestNewToken();
            } else {
                return true;
            }
        } else {
            this.logout(currentUrl);
            return false;
        }
    }

    /** Valida si existe un refresh token y si no ha expirado */
    isValidRefreshToken() {
        const currentTime = DateTime.now().toSeconds();
        const currentUrl = this.router.url;
        if (!this.refreshToken) {
            return false;
        }
        try {
            let decodedToken =
                this.jwtHelper.decodeToken(this.refreshToken) || null;
            let expirationTime = decodedToken.exp;
            let duration = expirationTime - currentTime;
            if (duration <= 0) {
                this.logout(currentUrl);
                return false;
            } else {
                return true;
            }
        } catch (error: any) {
            return false;
        }
    }

    /** Redirige a una url si se debe cerrar la sesión del usuario
     *  @param url La url que se estaba navegando cuando se cerró la sesión
     * @param alert Si se debe mostrar un mensaje de alerta al usuario. Si se muestra, adicionalmente se redirige a la página de login
     */
    async logout(url: string, alert = true) {
        this.dialog.closeAll();
        this._refreshToken = null;
        this._tokenExpirationTime = null;
        this.subscriptionService.isClient.set(false);
        this.subscriptionService.subscription$.next(null);
        this.store.dispatch(new Logout());
        this.clearStorage();
        this.tokenUnsubscribe();
        let title = await lastValueFrom(
            this.translate.get('NO_ACTIVE_SESSION_MESSAGE')
        );
        let message = await lastValueFrom(
            this.translate.get('NO_ACTIVE_SESSION_PROMPT')
        );
        // Solo mostrar el mensaje de alerta si el parámetro alert es verdadero
        if (alert) {
            this.router.navigateByUrl('/security/login');
            Swal.fire({
                ...ALERT_TOAST_DEFAULTS,
                icon: 'info',
                title: title,
                timer: 10000,
                text: message,
                customClass: {
                    container: 'wider-swal',
                },
                showConfirmButton: false,
            });
        }
        if (url && url != '/main') {
            const currentUrl = url;
            const [ruta, queryParamsString] =
                decodeURIComponent(currentUrl).split('?');
            // Guardar la ruta en el localStorage
            localStorage.setItem('ruta', ruta);
            // Si hay queryParams, guardarlos en el localStorage como un objeto
            if (queryParamsString) {
                const queryParamsArray = queryParamsString.split('&');
                const queryParams: any = {};
                queryParamsArray.forEach((param) => {
                    const [key, value] = param.split('=');
                    if (queryParams[key]) {
                        // Si el valor ya existe, convertirlo a un array y agregar el nuevo valor
                        if (!Array.isArray(queryParams[key])) {
                            queryParams[key] = [queryParams[key]];
                        }
                        queryParams[key].push(value);
                    } else {
                        // Si el valor no existe, guardarlo como una cadena
                        queryParams[key] = value;
                    }
                });
                localStorage.setItem(
                    'queryParams',
                    JSON.stringify(queryParams)
                );
            }
        }
    }

    /** Contiene la lógica para validar si el token está por expirar */
    isTokenAboutToExpire(): boolean {
        const currentTime = DateTime.now().toSeconds();
        // Verificar si existe un valor de expiración y si esta a menos de 3 minutos de expirar
        if (this.tokenExpirationTime) {
            let duration = this.tokenExpirationTime - currentTime;
            return duration <= 180;
        } else {
            return true;
        }
    }

    /** Solicitud de un nuevo token al back */
    async requestNewToken(): Promise<boolean> {
        const currentUrl = this.router.url;
        let data = {
            jwt_refresh: this.refreshToken,
        };
        try {
            let response = await lastValueFrom(
                this.api.post(this.resources.requestToken, data, null, [
                    'button',
                ])
            );
            if (response && response.success && response.authorization) {
                this.updateStore(response.authorization);
                return true;
            } else {
                this.logout(currentUrl);
                return false;
            }
        } catch (error) {
            this.logout(currentUrl);
            return false;
        }
    }

    updateStore(authorization: any) {
        if (authorization.token) {
            let accessToken: AccessToken = {
                accessToken: authorization.token || '',
                expiresIn: this.jwtHelper.decodeToken(authorization.token).exp,
                tokenType: 'JWT',
            };

            this.store.dispatch(
                new SaveToken({
                    AccessToken: accessToken,
                })
            );
            this.tokenExpirationTime = accessToken.expiresIn;
        }
        if (authorization.refresh) {
            this.refreshToken = authorization.refresh;
        }
    }

    tokenUnsubscribe() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    hasAccess(code: string): boolean {
        return this.permissions.value.some(
            (permission: Permission) => permission.code == code
        );
    }

    clearStorage() {
        this.permissions.next([]);
        const total = localStorage.length;
        const keysToRemove = [];
        for (let i = 0; i < total; i++) {
            const key = localStorage.key(i);
            if (key && !key.startsWith(this.prefix)) {
                keysToRemove.push(key);
            }
        }
        keysToRemove.forEach((key) => localStorage.removeItem(key));
    }

    async updatePermissions() {
        const rawUserData = localStorage.getItem('UserData');
        if (rawUserData) {
            const userData = JSON.parse(JsEncode.decrypt(rawUserData));
            if (userData && userData.id_members_workspace) {
                try {
                    const permissionsResponse = await lastValueFrom(
                        this.api.get(
                            `${this.resources.permissions}/${userData.id_members_workspace}`
                        )
                    );
                    if (permissionsResponse && permissionsResponse.data) {
                        localStorage.setItem(
                            'permissions',
                            JSON.stringify(permissionsResponse.data)
                        );
                        this.permissions.next(permissionsResponse.data);
                    }
                } catch (error) {
                    return;
                }
            }
        }
    }

    /** Solicita la configuración del asistente al back y la almacena en el localStorage */
    async updateAssistantSettings() {
        try {
            const res = await lastValueFrom(
                this.api.get(this.resources.assistantSettingsByUser)
            );
            if (res && res.data) {
                localStorage.setItem(
                    'assistantSettings',
                    JSON.stringify(res.data)
                );
                this.assistantSettings.next(res.data);
            }
        } catch (error) {
            return;
        }
    }

    /** Solicita la configuración del global de las funcionalidades del sistema y la almacena en el localStorage */
    async updateFeatureConfig() {
        try {
            const res = await lastValueFrom(
                this.api.get(this.resources.featureConfig)
            );
            if (res && res.data) {
                localStorage.setItem('featureConfig', JSON.stringify(res.data));
                this.featureConfig.next(res.data);
            }
        } catch (error) {
            return;
        }
    }

    /** Solicita la configuración de acceso a las funcionalidades del sistema que tiene el despacho y la almacena en el localStorage */
    async updateFeatureAccess() {
        try {
            const res = await lastValueFrom(
                this.api.get(this.resources.featureAccessByWorkspace)
            );
            if (res && res.data) {
                localStorage.setItem('featureAccess', JSON.stringify(res.data));
                this.featureAccess.next(res.data);
            }
        } catch (error) {
            return;
        }
    }
}
