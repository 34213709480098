<div [formGroup]="docForm" class="h-full w-full document-component-wrapper">
    <div class="flex flex-col w-full h-full max-w-full max-h-full">
        <div *ngIf="editorReady" class="bg-white flex flex-row items-start p-2 px-4 sm:px-6 md:px-5 justify-between gap-2">
            <div class="w-full max-w-[30rem]">
                <app-input-field
					formControlName="title"
					[inputType]="'text'"
					[placeholder]="'Agregar título'"
					[generalError]="'Por favor introduzca un título válido.'"
				></app-input-field>
            </div>
            <div>
                <button (click)="closeEditor()" class="btn bg-pearl-100 border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600 px-4 flex gap-1 items-baseline"
                    [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Cerrar'">
                    <i class="fa-solid fa-chevron-left leading-none text-xs text-slate-400"></i>
                    <span>Volver</span>
                </button>
            </div>
        </div>
        <div class="relative w-full h-full overflow-auto">
            <ejs-documenteditorcontainer #document_editor (created)="onEditorCreated()" [serviceUrl]="serviceLink" [toolbarItems]="toolbarItems" (toolbarClick)="onToolbarClick($event)" (contentChange)="onContentChange($event)" (documentChange)="onDocumentChange($event)" (beforeXmlHttpRequestSend)="beforeXmlHttpRequestSend($event)"></ejs-documenteditorcontainer>
        </div>
    </div>
    <div *ngIf="!editorReady || loadingStates.loadingDocument.value" class="absolute flex top-0 left-0 w-full h-full bg-[#ffffff70] items-center justify-center">
        <svg class="animate-spin w-10 h-10 fill-current shrink-0" viewBox="0 0 16 16">
            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
        </svg>
    </div>
</div>