<div class="py-2 px-5 flex gap-1 border-b border-slate-200">
  <!-- Botón para volver a la lista principal -->
  <button
    class="grid size-[2.375rem] shrink-0 place-content-center rounded enabled:hover:bg-slate-300/60 enabled:hover:shadow-sm enabled:hover:text-slate-500 disabled:opacity-50 text-slate-400"
    (click)="returnToList()" [disabled]="!selectedCollection" [ngxTippy]="'Volver a Colecciones'">
    <i class="fa-solid fa-arrow-left"></i>
  </button>
  <!-- Input de búsqueda -->
  <div class="relative grow">
    <label for="simple-doc-picker-search" class="sr-only" translate="SEARCH_PLACEHOLDER_STRING"></label>
    <input (keydown.enter)="$event.preventDefault();onSearch()" id="simple-doc-picker-search"
      [formControl]="searchControl" class="form-input w-full !pr-14" type="search"
      [placeholder]="getInputPlaceholder()">
    <div class="absolute flex gap-2 right-3 top-2/4 -translate-y-2/4">
      <button type="submit" *ngIf="searchControl.value" aria-label="Limpiar" ngxTippy data-tippy-content="Limpiar"
        (click)="clearSearch()" class="px-2 border-r border-slate-200" [tippyProps]="{placement: 'top'}">
        <i class="fa-duotone error-icon fa-xmark text-[0.8rem]" style="--fa-secondary-opacity: 0.8 !important"></i>
      </button>
      <button (click)="onSearch()" type="submit" aria-label="Buscar" ngxTippy data-tippy-content="Clic aquí para buscar"
        [tippyProps]="{placement: 'top'}">
        <i class="fa-duotone fa-magnifying-glass secondary-icon text-[0.8rem]"></i>
      </button>
    </div>
  </div>
</div>

<!-- Listado de colecciones -->
<div class="bg-pearl-100 divide-y divide-slate-200 overflow-y-auto relative"
  *ngIf="filteredCollections[0] && !selectedCollection">
  <button type="button" *ngFor="let collection of filteredCollections; let i = index; trackBy:trackByFn"
    class="hover:bg-indigo-50 group flex gap-2 py-2 items-center px-5 w-full" (click)="selectCollection(collection)">
    <div class="font-medium text-slate-600 truncate text-sm">
      {{ collection.name_collection }}
    </div>
    <div
      class="size-7 rounded-full hover:bg-indigo-100 grid place-content-center invisible group-hover:visible ml-auto">
      <i class=" fa-solid fa-chevron-right text-[0.9rem]"></i>
    </div>
  </button>
</div>

<!-- Listado de search_objects cuando hay una colección seleccionada -->
<div class="pl-5 pr-4 overflow-auto"
  *ngIf="selectedCollection && !(loadingCollectionsData | async) && filteredSearchObjects[0]">
  <app-simple-search-object-data [objects]="filteredSearchObjects" (onSelect)="onSearchObjectClick($event)" />
</div>

<!-- Empty state si no hay colecciones -->
<div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-3"
  *ngIf="!searchObjects[0] && selectedCollection && !(loadingCollectionsData | async)">
  <div class="bg-indigo-100 size-20 grid place-content-center rounded-full">
    <i class="fa-solid fa-folder-open text-[2rem]"></i>
  </div>
  <span class="text-center font-medium">No encontramos resultados dentro de esta colección</span>
</div>

<!-- Loader de asuntos o colecciones -->
<div class="flex flex-col gap-2 animate-pulse pointer-events-none p-5" *ngIf="loadingCollectionsData | async">
  <div class="flex gap-2" *ngFor="let _ of [].constructor(5);">
    <div class="size-8 shrink-0 bg-gray-200 rounded-full"></div>
    <div class="flex flex-col gap-1 *:bg-gray-200 grow">
      <div class="w-9/12 h-3 rounded"></div>
      <div class="w-5/12 h-3 rounded"></div>
    </div>
  </div>
</div>