import { CommonModule } from '@angular/common';
import {
    Component,
    computed,
    effect,
    EventEmitter,
    inject,
    Input,
    Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BehaviorSubject } from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { COLLECTION_TYPES } from 'src/app/features/search/constants/collections.constants';
import { CollectionLite } from 'src/app/features/search/interfaces/collections.interfaces';
import { SearchObject } from 'src/app/shared/interfaces/search-object.types';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { CollectionsService } from 'src/app/shared/services/collections.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal from 'sweetalert2';
import { DocPickerSelection } from '../simple-doc-picker.component';
import { SimpleDocPickerService } from '../simple-doc-picker.service';
import { SimpleSearchObjectDataComponent } from '../simple-search-object-data/simple-search-object-data.component';

@UntilDestroy()
@Component({
    selector: 'app-select-collection-tab',
    standalone: true,
    imports: [
        CommonModule,
        NgxTippyModule,
        ReactiveFormsModule,
        SimpleSearchObjectDataComponent,
    ],
    templateUrl: './select-collection-tab.component.html',
})
export class SelectCollectionTabComponent {
    private readonly collectionsService = inject(CollectionsService);
    private readonly translate = inject(TranslateService);
    private readonly api = inject(UisrApiServiceV2);
    private readonly docPickerService = inject(SimpleDocPickerService);

    selectedCollection?: CollectionLite;
    searchObjects: any[] = []; // Listado de documentos de una colección
    filteredCollections: CollectionLite[] = [];
    filteredSearchObjects: any[] = [];
    searchControl: FormControl<null | string> = new FormControl(null);
    loadingCollectionsData = new BehaviorSubject(false);

    collections = computed(() => {
        return this.collectionsService
            .userCollections()
            .filter(
                (c) => c.fk_id_type_collection != COLLECTION_TYPES.DOSSIERS
            );
    });

    @Output() onCollectionChange = new EventEmitter<string | undefined>();

    constructor() {
        effect(() => (this.filteredCollections = this.collections()));
    }

    /** Permite seleccionar una colección y consultar sus search_objects. Solo se ejecuta si la colección seleccionada es diferente a la actual */
    selectCollection(collection: CollectionLite) {
        if (
            this.selectedCollection?.id_collection_user !=
            collection.id_collection_user
        ) {
            this.selectedCollection = collection;
            this.getCollectionData();
            this.onCollectionChange.emit(collection.name_collection);
        }
    }

    /** Obtiene los searchObjects asociados a la colección seleccionada */
    private getCollectionData() {
        if (!this.selectedCollection) return;

        let data: any = {
            num: 10000,
            page: 1,
            id_collection_user: this.selectedCollection.id_collection_user,
        };

        this.api
            .get(RESOURCES.getCollectionDetails, data)
            .pipe(
                loadingState(this.loadingCollectionsData),
                untilDestroyed(this)
            )
            .subscribe({
                next: (res: any) => {
                    if (res.success) {
                        this.searchObjects =
                            this.docPickerService.buildSelectableItems(
                                res.search_objects.data
                            );

                        this.filteredSearchObjects = this.searchObjects;
                    } else {
                        let message = this.translate.instant(
                            'GENERIC_ERROR_MESSAGE'
                        );
                        if (res.code) {
                            let messageAux = this.translate.instant(res.code);
                            message =
                                messageAux != res.code ? messageAux : message;
                        }
                        Swal.fire({
                            ...ALERT_DEFAULTS,
                            text: message,
                        });
                    }
                },
            });
    }

    /** Obtiene el texto que se debe mostrar en el searchInput según la vista en la que está el usuario */
    getInputPlaceholder() {
        return this.selectedCollection
            ? 'Busca por nombre del documento'
            : 'Busca por nombre de la colección';
    }

    /** Al realizar la búsqueda,  de lo contrario, filtrar los asuntos */
    onSearch() {
        if (this.selectedCollection) {
            this.filteredSearchObjects = this.searchObjects.filter(
                (c) =>
                    !this.searchControl.value ||
                    c.heading?.toLowerCase().includes(this.searchControl.value)
            );
        } else {
            this.filteredCollections = this.collections().filter(
                (c) =>
                    !this.searchControl.value ||
                    c.name_collection
                        ?.toLowerCase()
                        .includes(this.searchControl.value)
            );
        }
    }

    /** Limpia el input de búsqueda y realiza la búsqueda de colecciones con los filtros por defecto */
    clearSearch() {
        this.searchControl.setValue(null);
        this.onSearch();
    }

    /** Volver al listado principal ya sea de asuntos o de colecciones según selectedTab y reiniciar los listados de documentos */
    returnToList() {
        this.selectedCollection = undefined;
        this.searchObjects = [];
        this.onCollectionChange.emit(undefined);
    }

    trackByFn(index: any) {
        return index;
    }

    /** Al hacer click en un elemento de una colección, se agrega al arreglo de documentos seleccionados */
    onSearchObjectClick(object: DocPickerSelection<SearchObject>) {
        this.docPickerService.updateSelection(object);
    }
}
