<div class="overflow-auto bg-pearl-100">
  <ng-container *ngFor="let item of items; let i = index; trackBy:trackByFn">
    <!-- Carpeta o archivo -->
    <div class="-mt-px first:mt-0" [ngxTippy]="getTooBigTooltip(item)" [tippyProps]="{ allowHTML: true}">
      <button type="button"
        class="enabled:hover:bg-indigo-50 flex items-center gap-2 p-2 group border w-full border-transparent"
        (click)="container.onItemClick(item)"
        [disabled]="(container.loadingFolder | async) || docPickerService.isExisting(item) || (item && isTooBig(item))"
        [ngClass]="{'bg-indigo-50 !border-indigo-600 !relative': docPickerService.isSelected(item) || docPickerService.isExisting(item), 'opacity-50': isTooBig(item)}">
        <ng-container
          *ngIf="!(container.loadingFolder | async) || container.selectedFolder?.studio_file_id != item.studio_file_id">
          <i class="fa-regular text-[1rem] text-slate-600" [ngClass]="item.extension | extToIcon" *ngIf="item"></i>
          <i class="fa-regular text-[1rem] text-slate-600 fa-folder" *ngIf="!item"></i>
        </ng-container>
        <ng-container
          *ngIf="(container.loadingFolder | async) && container.selectedFolder?.studio_file_id == item.studio_file_id">
          <i class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i>
        </ng-container>
        <span class="font-medium text-slate-600 truncate text-sm">{{ item.name | titlecase }}</span>
        <div class="px-3 ml-auto" *ngIf="item.type_id != documentType">
          <div class="size-6 rounded-full hover:bg-indigo-100 grid place-content-center invisible group-hover:visible">
            <i class="fa-solid fa-chevron-right text-sm"></i>
          </div>
        </div>
        <div class="px-3 ml-auto pointer-events-none flex items-center" *ngIf="item.type_id == documentType">
          <div class="size-6 rounded-full grid place-content-center text-indigo-600 bg-indigo-100"
            [ngClass]="docPickerService.isSelected(item) || docPickerService.isExisting(item) ? 'visible': 'invisible'">
            <i class="fa-solid fa-check text-[0.9rem]"></i>
          </div>
          <div *ngIf="!docPickerService.isSelected(item) && !docPickerService.isExisting(item)"
            class="text-xs font-medium text-slate-500 text-nowrap">
            <i class="fa-regular fa-triangle-exclamation mr-1" *ngIf="isTooBig(item)"></i>
            {{item.size | fileSize}}
          </div>
        </div>
      </button>
    </div>

    <!-- Espacio para mostrar las sub-carpetas o documentos dentro una carpeta -->
    <div class="pl-5">
      <app-studio-item-data [items]="item.folders || []" *ngIf="item.folders && item.open"
        [parentId]="item.studio_file_id" />
    </div>
  </ng-container>

  <!-- Empty state si no hay carpetas o documentos dentro del asunto -->
  <div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-2"
    *ngIf="!items[0] && (!isLoading() || parentId && container.selectedFolder?.studio_file_id != parentId)">
    <div class="bg-indigo-100 size-8 grid place-content-center rounded-full">
      <i class="fa-solid fa-folder-open text-[0.8rem]"></i>
    </div>
    <span class="text-center font-medium text-sm">No encontramos documentos en esta ubicación</span>
  </div>
</div>