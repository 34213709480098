import { DOCUMENT } from '@angular/common';
import {
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { RESOURCES } from '../../constants/resource-service.constants';
import { APIResponse } from '../../interfaces/api.interface';
import { UserData } from '../../models/user-data';
import { UserDataLocalStorage } from '../../reducer/user-data/user-data.actions';
import { UserDataFull } from '../../reducer/user-data/user-data.selector';
import { DialogService } from '../../services/dialog.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import {
  CONFIGURATION_STEPS,
  EXPLORADOR_DIGITAL_STEPS,
  INNOVADOR_LEGAL_STEPS,
  VISIONARIO_JURIDICO_STEPS,
} from '../../constants/user-journey.constants';
import { UisrAuthService } from '../../services/uisr-auth.service';
import { WebSocketService } from '../../services/v2-socket.io.service';
import { SidebarComponent } from '../sidebar/sidebar.component';

@UntilDestroy()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  readonly dialogService = inject(DialogService);
  private readonly subscriptionService = inject(SubscriptionService);

  @ViewChild(SidebarComponent, { static: true })
  sidebarComponent!: SidebarComponent;
  @ViewChild('trialCallToAction') trialCallToAction!: TemplateRef<any>;

  lastScrollTop: any = 0;
  loadingSubscription = new BehaviorSubject(false);
  journeySteps: any[] = []
  incompleteSteps: any = []
  userSubscription:any = []
  differenceInDays:number = 0
  showParagraph = true;
  userData = {} as UserData;

  readonly resources = RESOURCES;

  private readonly apiService = inject(UisrApiServiceV2);

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private authService: UisrAuthService,
    public socketService: WebSocketService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new UserDataLocalStorage());

    this.store
      .pipe(
        select(UserDataFull),
        distinctUntilChanged(isEqual),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.userData = data;
      });

    this.authService.startValidationLoop();
    this.authService.updatePermissions();
    this.authService.updateAssistantSettings();
    this.renderer.addClass(this.document.body, 'inner-app');

  }
  
  ngAfterViewInit(): void {
    this.setSubscriptionDates();
    this.store
      .pipe(
        select(UserDataFull),
        distinctUntilChanged(isEqual),
        untilDestroyed(this)
      )
      .subscribe((data: UserData) => {
        if (data?.id_users && data?.idWorkspace) {
          this.updateTimeZoneIfNeeded(data);
        }
      });
  }

  /**
   * Verifica y actualiza la zona horaria si es diferente.
   */
  private updateTimeZoneIfNeeded(userData: UserData): void {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Obtiene la zona horaria actual

    if (userData?.time_zone !== currentTimeZone) { // Compara la zona horaria actual con la del userData
      const updatedData = {
        timeZone: currentTimeZone,
        allowLocation: true,
      };

      this.apiService
        .post(`${this.resources.calendarSettings}`, updatedData)
        .subscribe({
          next: () => {
            console.log('Zona horaria actualizada correctamente');
          },
          error: (error) => {
            console.error('Error al actualizar la zona horaria', error);
          },
        });
    }
  }

  openSidebar() {
    this.sidebarComponent.openOrCloseSidebar();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'inner-app');
  }

  private setSubscriptionDates() {
    const subscription = this.subscriptionService.subscription$.value;

    if (subscription?.subscriptionExpire) {
      const currentDate = new Date(); // Fecha actual
      const expirationDate = new Date(subscription.subscriptionExpire); // Fecha de expiración

      // Calcula la diferencia en milisegundos
      const differenceInMs = expirationDate.getTime() - currentDate.getTime();

      // Convertir la diferencia de milisegundos a días (1 día = 86,400,000 ms)
      this.differenceInDays = Math.floor(
        differenceInMs / (1000 * 60 * 60 * 24)
      );
    }

    if (subscription) {
      this.authService.updateFeatureConfig();
      this.authService.updateFeatureAccess();
    }
  }
  
  hideParagraph() {
    this.showParagraph = false;
    localStorage.setItem('showParagraph', JSON.stringify(this.showParagraph));
  }
}
