import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import mime from 'mime';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BehaviorSubject, Subject } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { DialogService } from 'src/app/core/services/dialog.service';
import { WebSocketService } from 'src/app/core/services/v2-socket.io.service';
import { ALERT_TOAST_DEFAULTS } from 'src/app/features/calendar/constants/alert-defaults.constants';
import { ActivityFile } from 'src/app/features/law-firm/interfaces/activity.interfaces';
import { StudioFiles } from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal from 'sweetalert2';
import { PdfViewerModalComponent } from '../../../pdf-viewer-modal/pdf-viewer-modal.component';
import { AssistantChatService } from '../../services/assistant-chat.service';

@UntilDestroy()
@Component({
    selector: 'app-view-doc',
    standalone: true,
    imports: [CommonModule, TranslateModule, NgxTippyModule],
    templateUrl: './view-doc.component.html',
})
export class ViewDocComponent implements OnInit {
    private readonly api = inject(UisrApiServiceV2);
    private readonly _dialogService = inject(DialogService);
    private readonly _socketService = inject(WebSocketService);
    readonly data = inject(DIALOG_DATA, { optional: true });
    readonly dialogRef = inject(DialogRef);
    readonly chatService = inject(AssistantChatService);

    /** Información del documento a visualizar, puede ser de diferentes tipos según los documentos que acepte Amparo IA. 
     * Actualmente se aceptan documentos del asunto, asunto, documentos del estudio y legislación desde colecciones */
    doc: any;
    loading = new BehaviorSubject(false); // Indica que esta cargando la información
    vectorizing = new BehaviorSubject(false); // Indica que se esta reintentando la vectorización
    downloading = new BehaviorSubject(false); // Indica que se está descargando
    running = new BehaviorSubject(false); // Indica que hay un run en proceso para vectorizar el documento
    update = new Subject(); // Cuando se emite, se debe actualizar la información del documento
    /** Indica si se puede remover un documento de una conversación */
    canRemove = true;
    /** Indica si se ha fallado la vectorización y se habilita la posibilidad de reintentar */
    runFailed = false;

    constructor() {
        if (this.data.doc) {
            this.doc = this.data.doc;
        }

        if (this.data.canRemove) {
            this.canRemove = this.data.canRemove;
        }
    }

    ngOnInit(): void {
        this.onUpdateRequest();
        this._getData();
    }

    /** Cuando se recibe el evento update, se actualiza toda la información del documento */
    onUpdateRequest() {
        this.update.pipe(untilDestroyed(this)).subscribe(() => {
            this._getData();
        });
    }

    /** Solicita la actualización del documento según su tipo */
    private _getData() {
        if (this.doc.type == 'dossierFile' || this.doc.idActivityFile) {
            this._getDocument();
        } else if (this.doc.type == 'studioFile') {
            this._getStudioFile();
        } else if (this.doc.type == 'dossier') {
            this._getDossier();
        } else {
            this._getSearchObject();
        }
    }

    /** Solicitud para obtener documento de tipo activity_file */
    private _getDocument() {
        this.api
            .get(RESOURCES.activityFiles, {
                idActivityFile: this.doc.idActivityFile,
                includeDossier: true,
            })
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<ActivityFile[]>) => {
                    this.doc = { ...res.data[0], type: 'dossierFile' };

                    if (!res.data[0].ai_summary) {
                        this._getVectorRun({
                            id_workspace_file: this.doc.idActivityFile,
                        });
                    }
                },
            });
    }

    /** Solicitud para obtener documento de tipo studio_file */
    private _getStudioFile() {
        this.api
            .get(RESOURCES.studioFiles, {
                studio_file_id: this.doc.studio_file_id,
            })
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<StudioFiles[]>) => {
                    this.doc = { ...res.data[0], type: 'studioFile' };

                    if (!res.data[0].ai_summary) {
                        this._getVectorRun({
                            id_studio_file: this.doc.studio_file_id,
                        });
                    }
                },
            });
    }

    /** Solicitud para obtener un dossier con los detalles de la vectorización */
    private _getDossier() {
        if (!this.doc.id) return;
        this.api
            .get(`${RESOURCES.getDossiersDetail}/${this.doc.id}`)
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<StudioFiles[]>) => {
                    this.doc = { ...res.data[0], type: 'dossier' };
                    if (!res.data[0].ai_summary) {
                        this._getVectorRun({
                            id_dossiers: this.doc.idDossier,
                        });
                    }
                },
            });
    }

    private _getVectorRun(filter: any) {
        this.running.next(false);
        this.api
            .post(RESOURCES.vectorRun, filter)
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    const run = res.data[0];
                    if (!run) return;

                    const timeTaken = DateTime.fromISO(run.created_at).diffNow()
                        .minutes;

                    if (run.status == 'success') {
                        this._getData();
                    } else if (
                        run.status == 'failed' ||
                        (run.status == 'in_progress' && timeTaken > 30)
                    ) {
                        this.running.next(false);
                        this.runFailed = true;
                    } else if (run.status != 'failed') {
                        this.running.next(true);
                        this._subscribeToVectorRun(run.run_id);
                    }
                },
            });
    }

    private _subscribeToVectorRun(run_id: string) {
        this._socketService.socket?.on(
            `assistants_update_${run_id}`,
            (event: any) => {
                this.update.next(true);
                this.running.next(false);
            }
        );
    }

    /** Solicitud para obtener documento de tipo search_object */
    private _getSearchObject() {
        let data = {
            id_open_search:
                this.doc.id || this.doc.openSearchId || this.doc.id_open_search,
            fk_id_index: this.doc.index || this.doc.fk_id_index,
        };

        this.api
            .get(RESOURCES.searchObjectWithAiAttributes, data)
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    this.doc = { ...res.data, type: 'searchObject' };

                    if (!res.data.attributes?.ai_summary) {
                        this._getVectorRun({
                            id_open_search: this.doc.id_open_search,
                            id_index: this.doc.fk_id_index,
                        });
                    }
                },
            });
    }

    preview() {
        this._dialogService.openSidePanel(PdfViewerModalComponent, {
            data: this.doc.publicUrl,
        });
    }

    download() {
        if (this.doc.deleted) return;
        if (!this.doc || !this.doc.publicUrl) {
            Swal.fire({
                ...ALERT_TOAST_DEFAULTS,
                icon: 'error',
                text: 'No fue posible encontrar el documento',
            });
            return;
        }
        this.api
            .get(`${RESOURCES.getFileContent}/${this.doc.idActivityFile}`)
            .pipe(loadingState(this.downloading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<string>) => {
                    const type = mime.getType(this.doc.extension);
                    const src = `data:${type};base64,${res.data}`;
                    const link = document.createElement('a');
                    link.href = src;
                    link.download = this.doc.name;
                    link.click();

                    link.remove();
                },
            });
    }

    runAgain() {
        this.api
            .post(`${RESOURCES.assistantVectorize}`, { docs: [this.doc] })
            .pipe(untilDestroyed(this), loadingState(this.vectorizing))
            .subscribe({
                next: (res) => {
                    this._getData();
                },
            });
    }
}
